/* ===================================================================================================================
   Accordion
   ================================================================================================================ */

.ce_accordion { }

.ce_accordion .toggler {
	position       : relative;
	outline        : 0;
	cursor         : pointer;
	padding        : rem-calc($grid-column-gutter-medium)/2 0;
	text-transform : uppercase;
	font-size      : rem-calc(15);
	font-weight    : $font-weight-1;
	letter-spacing : $letter-spacing-medium;
	
	@include icon(filter3, before) {
		margin-right : 5px;
		font-size    : 16px;
	}
	
	@include icon(arrow-down3, after) {
		position  : absolute;
		top       : calc(50% - 7px);
		right     : 0;
		font-size : 14px;
	}
	
	@include breakpoint(medium) {
		//padding-top : rem-calc($grid-column-gutter-medium);
	}
}

.ce_accordion .toggler:focus,
.ce_accordion .toggler:hover { }

.ce_accordion .toggler.active {
	@include icon(arrow-up3, after) {
		font-size : 16px;
	}
}

.ce_accordion .ui-accordion-content-active {
	padding-bottom : rem-calc($grid-column-gutter-medium)-1rem;
}
