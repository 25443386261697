/* ===================================================================================================================
   Pagination
   ================================================================================================================ */

.pagination {
	@include flex-grid-row();
}

.pagination ul {
	text-align  : right;
	@include flex-grid-column(12);
	margin-left : 0;
}

.pagination p,
.pagination ul li.next,
.pagination ul li.last,
.pagination ul li.first,
.pagination ul li.previous { display : none !important; visibility : hidden; }

.pagination ul { display : inline-block; }

.pagination li { display : inline-block; margin-left : 5px; }

.pagination li:first-child { margin-left : 0; }

.pagination li a,
.pagination li span { border : 1px solid $primary-color; padding : 5px 10px; }

.pagination li a { text-decoration : none; }

.pagination li span { color : $white; background : $primary-color; }

.pagination li a:hover,
.pagination li a:focus { color : $white; background : $secondary-color; }
