/* ===================================================================================================================
   Modal (Reveal)
   ================================================================================================================ */

.link_modal-open {
	//font-weight    : $font-weight-3;
	padding-bottom : 1px;
	border-bottom  : 1px solid $black;
}

.reveal .button_wrapper {
	text-align : center;
}
