/* ===================================================================================================================
   Isotope
   ================================================================================================================ */

[class*="mod_iso"] .clear {
	display    : none !important;
	visibility : hidden !important;
}

// Button Layout ausgelagert nach 'button.scss'
[class*="mod_iso"] .review a.edit {
	float    : right;
	overflow : hidden;
}

// -------------------------------------------------------------------------------------------------------------------
// Produkt - Liste
// -------------------------------------------------------------------------------------------------------------------

.mod_iso_productlist img {
	//background : #535152; // TEMP Aus Layout Vorlage extrahiert, da Bilder im Original keinen Hintergrund haben
}

.mod_iso_productlist .image_container {
	text-align : center;
}

//TODO-PF: Abstand besser global an 'page_content' (Layout) vergeben
.mod_iso_productlist .empty.message {
	margin-top : $global-margin;
	@include breakpoint(large) {
		margin-top : 0;
	}
}

.product_list .product h3,
.product_list .product .sku,
.product_list .product .details {
	//display    : none !important;
	//visibility : hidden !important;
	//@include hide-for(small); // FS > Nur Display None, kein Visibility Hidden?!
}

.product_list .product h3 {
}

.product_list {
	@include flex-grid-row;
	//max-width : none;
	//@include grid-remove-row-margin;
}

.product_list .product {
	@include flex-grid-column(6);
	margin-bottom : map-get($grid-column-gutter, small)+17;
	@include breakpoint(medium) {
		@include flex-grid-column(3);
		margin-bottom : map-get($grid-column-gutter, medium)+17;
	}
	
	// TODO PF TMP/CHECK
	//@include breakpoint(large) {
	//	@include flex-grid-column(3);
	//}
	
	@include breakpoint(xxlarge) {
		//@include flex-grid-column(2);
	}
	
}

.product_list.similar_products {
	margin-top  : 3rem;
	padding-top : 3rem;
	border-top  : 1px solid $medium-gray;
}

.product_list.similar_products .product {
	@include flex-grid-column(6);
	margin-bottom : map-get($grid-column-gutter, small)+17;
	@include breakpoint(medium) {
		@include flex-grid-column(2);
		margin-bottom : map-get($grid-column-gutter, medium)+17;
	}
	margin-top    : 2rem;
}

.product_list .formbody { position : relative; }

.product_list .image_container {
	background : $white;
}

.product_list .image_container img {
	width : 100%;
}

.product_list .price {
	z-index : 2;
	//position   : absolute;
	//left       : 0;
	//bottom     : rem-calc(-17);
	//padding    : rem-calc(5);
	//font-size  : rem-calc(16);
	//color      : $white;
	//background : $primary-color;
}

.product_list .details {
	display    : none !important;
	visibility : hidden !important;
}

.product_list .property_status-new {
	z-index  : 2;
	position : absolute;
	top      : 0;
	right    : 0;
	
	//@include icon(new, before) {
	//	color        : $white;
	//	margin-right : 5px;
	//	font-size    : 32px;
	//}
}

.product_list .property_status-new:before {
	z-index          : 2;
	position         : absolute;
	content          : 'NEU';
	top              : 0;
	right            : 0.5em;
	font-family      : $body-font-family !important;
	background-color : $primary-color;
	font-size        : rem-calc(18);
	padding          : 4px;
	padding-top      : 6px;
	color            : $white;
	line-height      : 1;
}

// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------

//.product_list form,
//.product_list .iso_list-wrapper-outside {
//	@include flex-grid-row;
//	height : 100%; // TODO Funktioniert nicht in Safari! Siehe: https://bugs.webkit.org/show_bug.cgi?id=137730 (ggf. auch https://bugs.webkit.org/show_bug.cgi?id=136041)
//
//	flex-basis: auto;
//}
//
//.product_list .formbody,
//.product_list .iso_list-wrapper-image,
//.product_list .iso_list-wrapper-price {
//	@include flex-grid-column(12);
//	padding-left  : 0;
//	padding-right : 0;
//	@include breakpoint(medium) {
//		padding-left  : 0;
//		padding-right : 0;
//	}
//}
//
//.product_list .formbody {
//	background : #535152;
//}
//
//.product_list .iso_list-wrapper-image {
//	@include flex-align-self(bottom);
//}
//
//.product_list .iso_list-wrapper-price {
//	//@include flex-align-self(bottom);
//}

//.product_list .image_container {
//	position        : absolute;
//	top             : 0;
//	left            : 0;
//	right           : 0;
//	bottom          : 0;
//	//overflow        : hidden;
//	height                      : 100%;
//	width                       : 100%;
//}
//.product_list img {
//	position          : relative;
//	top               : 50%;
//	text-align        : center;
//	-webkit-transform : translateY(-50%);
//	-moz-transform    : translateY(-50%);
//	-ms-transform     : translateY(-50%);
//	-o-transform      : translateY(-50%);
//	transform         : translateY(-50%);
//}

// -------------------------------------------------------------------------------------------------------------------
// Produkt - Filter
// -------------------------------------------------------------------------------------------------------------------

.mod_iso_productfilter .column_filter {
	@include flex-grid-column(12);
	@include breakpoint(xsmall) {
		@include flex-grid-column(6);
	}
	@include breakpoint(medium) {
		@include flex-grid-column(3);
	}
}

//.mod_iso_productfilter .column_sort {
//	@include flex-grid-column(12);
//	@include breakpoint(xsmall) {
//		@include flex-grid-column(6);
//	}
//	@include breakpoint(750) {
//		@include flex-grid-column(3);
//	}
//}
//
//.mod_iso_productfilter .column_search {
//	@include flex-grid-column(12);
//	@include breakpoint(xsmall) {
//		@include flex-grid-column(6);
//	}
//	@include breakpoint(750) {
//		@include flex-grid-column(6);
//	}
//}

.mod_iso_productfilter .column_clear {
	@include flex-grid-column(12);
	@include breakpoint(750) {
		@include flex-grid-column(3);
	}
}

// -------------------------------------------------------------------------------------------------------------------
// Produkt - Filter - Suche
// -------------------------------------------------------------------------------------------------------------------

.mod_iso_productfilter .search { position : relative; }

.mod_iso_productfilter .search label {
	display    : none;
	visibility : hidden;
}

.mod_iso_productfilter .search .awesomplete {
	display : block;
	width   : 100%;
}

.mod_iso_productfilter .search input[type="text"] {
	padding-right : 50px;
	padding-left  : 15px;
}

.mod_iso_productfilter .search input[type="submit"] {
	position        : absolute;
	top             : 0;
	right           : 0;
	width           : 40px;
	height          : 2.4375rem;
	margin          : 0;
	background      : $primary-color url("../img/icon-search.svg") no-repeat center;
	background-size : 20px 20px;
}

// -------------------------------------------------------------------------------------------------------------------
// Produkt - Leser
// -------------------------------------------------------------------------------------------------------------------

.mod_iso_productreader img {
	background : #535152;
}

.product_information .name,
	//.product_information .options label,
.product_information .quantity_container label {
	display    : none !important;
	visibility : hidden !important;
}

.mod_iso_productreader .formbody {
	@include flex-grid-row;
	max-width : none;
	@include grid-remove-row-margin;
}

.product_gallery,
.product_information {
	@include flex-grid-column(12);
	@include breakpoint(xsmall) {
		@include flex-grid-column(6);
	}
}

.product_gallery-thumbnails {
	margin-top : $global-margin;
	@include flex-grid-row;
	max-width  : none;
	@include grid-remove-row-margin;
}

.product_gallery-thumbnails figure {
	@include flex-grid-column(4);
}

//TODO-PF: Modal (Foundation?!) Einbindung
.product_shipping-information { }

.product_delivery-information { }

.product_information .product_category {
	font-size      : rem-calc(20); // TODO-PF: Muss noch sauber angepasst werden
	margin-top     : $global-margin;
	text-transform : uppercase;
	margin-bottom  : 0;
	font-weight    : $font-weight-0;
	@include breakpoint(xsmall) {
		margin-top : 0;
	}
	@include breakpoint(medium) {
		font-size : rem-calc(22); // TODO-PF: Muss noch sauber angepasst werden
	}
	@include breakpoint(large) {
		font-size : rem-calc(26); // TODO-PF: Muss noch sauber angepasst werden
	}
}

.product_information .sku {
	margin-bottom  : $global-margin;
	text-transform : uppercase;
	letter-spacing : $letter-spacing-medium;
}

.product_information .price {
	margin-bottom : 0;
	font-size     : rem-calc(18);
	@include breakpoint(medium) {
		font-size : rem-calc(24);
	}
}

.product_additional-information {
	font-size     : rem-calc(12);
	margin-bottom : $margin-medium;
}

.mod_iso_productreader .quantity_container {
	//display      : inline-block;
	float        : left;
	width        : 40px;
	margin-right : 10px;
}

.product_information .quantity_container input {
	text-align : center;
}

.mod_iso_productreader .submit {
	display    : inline-block;
	margin-top : 0;
}

.mod_iso_productreader .product_properties {
	margin-top : $global-margin;
}

.mod_iso_productreader .property_finish ul {
	display         : inline;
	margin-left     : 0;
	list-style-type : none;
}

.mod_iso_productreader .property_finish li {
	display : inline;
}

.mod_iso_productreader .product_information-ring-measure {
	//margin-top : $global-margin;
	
}

.mod_iso_productreader .product_information-ring-measure p {
	font-size : rem-calc(12);
	//@include icon(info2, before) {
	//	margin-right : 5px;
	//	font-size    : 16px;
	//}
}

.mod_iso_productreader .product_information-ring-measure a {
	padding-bottom : 1px;
	border-bottom  : 1px solid #232323;
}

.mod_iso_productreader .product_information-delivery {
	font-size  : rem-calc(14);
	margin-top : $global-margin;
}

//.mod_iso_productreader .property_status-new {
//	margin : -10px 0;
//	@include icon(new, before) {
//		margin-right : 5px;
//		font-size    : 32px;
//	}
//}

.mod_iso_productreader .property_status-new:before {
	z-index          : 2;
	position         : relative;
	content          : 'NEU';
	top              : -10px;
	right            : 0;
	font-family      : $body-font-family !important;
	background-color : $primary-color;
	font-size        : rem-calc(18);
	padding          : 4px;
	color            : $white;
	line-height      : 1;
}

.mod_iso_productreader .property_status-ordered {
	margin-top : $global-margin;
}

.mod_iso_productreader .property_status-ordered span {
	@include icon(warning2, before) {
		margin-right : 5px;
		font-size    : 14px;
	}
}

// -------------------------------------------------------------------------------------------------------------------
// Warenkorb
// -------------------------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------------------------
// Basket - Maxi
// -------------------------------------------------------------------------------------------------------------------
//.page_content-basket-maxi {}
//.basket-maxi_content {}
//.basket-maxi_summary {}
// -------------------------------------------------------------------------------------------------------------------

.page_content-basket-maxi table {
	width        : 100%;
	table-layout : fixed;
}

.basket-maxi_content tr {
	border-bottom : 1px solid $primary-color;
}

.basket-maxi_content td.remove,
.basket-maxi_summary td.remove,
.basket-maxi_summary td.col_0,
.basket-maxi_summary td.col_3 {
	display    : none !important;
	visibility : hidden !important;
}

.basket-maxi_content .image {
	display        : none;
	visibility     : hidden;
	padding-top    : 5px;
	padding-bottom : 5px;
	padding-right  : 15px;
	@include breakpoint(xsmall) {
		display    : block;
		visibility : visible;
		width      : 50px;
	}
	@include breakpoint(medium) {
		width : 100px;
	}
}

.basket-maxi_content .remove a {
	@include icon(cancel-square2, before);
	float : right;
}

.basket-maxi_content .name {
	width       : 100%;
	font-size   : rem_calc(14) !important;
	display     : block;
	padding     : 0.25em 0.5em 0.25em 0;
	font-weight : $font-weight-3;
	@include breakpoint(xsmall) {
		display      : table-cell;
		padding-left : 50px;
	}
	@include breakpoint(medium) {
		padding-left : 100px;
	}
}

// Attribute - Start
.basket-maxi_content .name ul {
	margin-left     : 0;
	list-style-type : none;
}

.basket-maxi_content .name ul strong {
	font-weight : $global-weight-normal;
}

.basket-maxi_content .name a,
.basket-maxi_content .name ul li p {
	font-size : rem_calc(14) !important;
}

// Attribute - Stop

.basket-maxi_content .quantity {
	display : inline-block;
	width   : 20%;
	padding : 0.25em 0.5em 0.25em 0;
	@include breakpoint(xsmall) {
		display      : table-cell;
		width        : rem-calc(42);
		padding-left : 0.5em;
	}
}

.basket-maxi_content .quantity input {
	width      : rem-calc(40);
	height     : rem-calc(22);
	margin     : 0;
	padding    : 0;
	text-align : center;
}

.basket-maxi_content .price,
.basket-maxi_content .price.total {
	display    : inline-block;
	width      : 40%;
	text-align : right;
	padding    : 0.25em;
	@include breakpoint(xsmall) {
		display : table-cell;
		width   : rem-calc(120);
		padding : 0.25em 0.5em;
	}
}

.basket-maxi_content .price.total {
	padding-right : 0;
}

// -------------------------------------------------------------------------------------------------------------------

.basket-maxi_summary .name {
	text-align : right;
}

.basket-maxi_summary .price.total {
	width      : rem-calc(120);
	padding    : 0.25em 0.5em;
	text-align : right;
}

.basket-maxi_summary .price.total {
	padding-right : 0;
}

.basket-maxi_summary .total .name,
.basket-maxi_summary .total .price { font-weight : $font-weight-3; }

.mod_iso_cart .coupon {
	margin-top : $global-margin;
}

.mod_iso_cart .coupon input {
	max-width : 200px;
}

// -------------------------------------------------------------------------------------------------------------------
// Basket - Mini
// -------------------------------------------------------------------------------------------------------------------
.page_content-basket-mini .coupon {
	display    : none !important;
	visibility : hidden !important;
}

.page_content-basket-mini h2 {
	margin-bottom  : $global-margin;
	line-height    : 1;
	font-size      : rem-calc(15);
	font-weight    : $font-weight-1;
	letter-spacing : $letter-spacing-medium;
	text-transform : uppercase;
	@include icon(cart5, before) {
		font-size : 16px;
	}
}

.page_type-shopreader .page_content-basket-mini h2 {
	//margin-top : $global-margin;
}

.page_content-basket-mini .product {
	margin-bottom  : rem-calc(5);
	padding-bottom : 0.5em;
	border-bottom  : 1px solid $primary-color;
}

.page_content-basket-mini .name {
	font-weight : $font-weight-3;
}

.page_content-basket-mini .remove { float : right; overflow : hidden; }

.page_content-basket-mini .remove a {
	@include icon(cancel-square2, after);
}

.page_content-basket-mini .price {
	text-align : right;
}

.page_content-basket-mini .info ul {
	margin-left     : 0;
	list-style-type : none;
}

.page_content-basket-mini .info ul strong {
	font-weight : $global-weight-normal;
}

.page_content-basket-mini .subtotal { text-align : right; }

.page_content-basket-mini .coupon h2 {
	margin-bottom  : $global-margin;
	line-height    : 1;
	font-size      : rem-calc(15);
	font-weight    : $font-weight-1;
	letter-spacing : $letter-spacing-medium;
	text-transform : uppercase;
	@include icon(price-tag4, before) {
		font-size : 16px;
	}
}

// -------------------------------------------------------------------------------------------------------------------
// Checkout - Steps
// -------------------------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------------------------
// Checkout - Adressen
// -------------------------------------------------------------------------------------------------------------------

// TODO-PF: Lösung finden!
// Funktioniert nur bei 'billingadress' nicht bei 'shippingaddress'
// Ursache liegt in 'isotope.js' Zeile 57 'Toggle Adress Fields'
.mod_iso_checkout .address_new {
	@include flex-grid-row;
	max-width : none;
	@include grid-remove-row-margin;
}

.mod_iso_checkout .address_new .widget {
	@include flex-grid-column(6);
}

.mod_iso_checkout .address_new .widget:nth-child(1),
.mod_iso_checkout .address_new .widget:nth-child(10) {
	@include flex-grid-column(12);
}

.mod_iso_checkout .shippingaddress {
	margin-top  : $global-margin;
	padding-top : $global-padding;
	border-top  : 1px solid $black;
}

.mod_iso_checkout .shippingaddress .widget-radio span {
	display : block;
}

// -------------------------------------------------------------------------------------------------------------------
// Checkout - Versand
// -------------------------------------------------------------------------------------------------------------------

.mod_iso_checkout .shippingmethod .widget-radio input[type="radio"] label {
	//display    : none;
	//visibility : hidden;
	display : block;
}

.mod_iso_checkout .shippingmethod .widget-radio .radio_container > span {
	display : block;
}

.mod_iso_checkout .shippingmethod .widget-radio > * {
	display : inline;
}

.mod_iso_checkout .shippingmethod .widget-radio label {
	margin : 0;
}

// -------------------------------------------------------------------------------------------------------------------
// Checkout - Zahlung
// -------------------------------------------------------------------------------------------------------------------

.mod_iso_checkout .paymentmethod .radio_container span {
	display : block;
}

// -------------------------------------------------------------------------------------------------------------------
// Checkout - Übersicht + Order Details
// -------------------------------------------------------------------------------------------------------------------

.mod_iso_checkout .orderinfo .info_container:first-of-type,
.mod_iso_orderdetails .info_container:first-of-type {
	margin-top  : $global-margin;
	padding-top : $global-padding;
	border-top  : 1px solid $black;
}

.mod_iso_checkout .orderinfo .info_container,
.mod_iso_orderdetails .info_container {
	margin-bottom  : $global-margin;
	padding-bottom : $global-padding;
	border-bottom  : 1px solid $black;
}

.mod_iso_checkout .orderinfo .info_container.last,
.mod_iso_orderdetails .info_container.last {
	margin-bottom : 0;
}

.mod_iso_checkout .orderinfo .info_container.shipping_method a.edit {
	display    : none;
	visibility : hidden;
}

.mod_iso_checkout .orderconditionsafterproducts {
	//margin-top  : $global-margin;
	padding-top : $global-padding;
	border-top  : 1px solid $black;
}

// -------------------------------------------------------------------------------------------------------------------

.mod_iso_orderdetails h2 {
	font-size : rem-calc(18);
}

// -------------------------------------------------------------------------------------------------------------------
// Adressbuch
// -------------------------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------------------------
// Bestell Historie
// -------------------------------------------------------------------------------------------------------------------

.mod_iso_orderhistory table {
	width      : 100%;
	text-align : left;
}

.mod_iso_orderhistory tr { border-bottom : 1px solid $black; }

.mod_iso_orderhistory td { padding : 10px 0; }

.mod_iso_orderhistory th:nth-child(2),
.mod_iso_orderhistory td:nth-child(2) {
	padding-left  : 5px;
	padding-right : 5px;
}

//

.mod_iso_orderhistory .oder_status { text-align : right; }

//.mod_iso_orderhistory .oder_status { width : 100%; }

// -------------------------------------------------------------------------------------------------------------------
// Mootools Overlay mit Isotope Meldungen
// modules/isotope/assets/css/isotope.css
// -------------------------------------------------------------------------------------------------------------------

#iso_ajaxBox {
	width      : 80%;
	padding    : 20px;
	position   : absolute;
	top        : 20px;
	left       : 10%;
	border     : 1px solid #000000;
	/*	font-family:"Trebuchet MS",Verdana,sans-serif;
		font-size:15px;
		line-height: 18px;*/
	text-align : center;
	color      : #000000;
	z-index    : 9999;
}

#iso_ajaxBox.btnClose {
	background-image : none;
	cursor           : pointer;
}

#iso_ajaxBox p {
	margin  : 1em 0;
	padding : 0;
}

#iso_ajaxBox .iso_error {
	padding-left : 0;
	background   : transparent;
	/*	padding-left: 34px;
		background: url("../images/cross-24.png") left center no-repeat;*/
}

#iso_ajaxBox .iso_confirm {
	padding-left : 0;
	background   : transparent;
	/*	padding-left: 34px;
		background: url("../images/tick-24.png") left center no-repeat;*/
}

#iso_ajaxBox .iso_info {
	padding-left : 0;
	background   : transparent;
	/*	padding-left: 34px;
		background: url("../images/information-24.png") left center no-repeat;*/
}

#iso_ajaxOverlay {
	width      : 100%;
	height     : 100%;
	position   : fixed;
	top        : 0;
	left       : 0;
	background : rgba(34, 34, 34, 0.85);
	z-index    : 9998;
	/*	background-color : #000000;
		-moz-opacity     : 0.5;
		opacity          : 0.5;
		_display         : none;
		filter           : Alpha(opacity=50);*/
}

// Original CSS von Isotope
// -------------------------------------------------------------------------------------------------------------------

//#iso_ajaxBox {
//	width:20%;
//	padding:9px 50px 9px 24px;
//	position:absolute;
//	top:30px;
//	left:40%;
//	background:#fff url("../../../../themes/default/images/loading.gif") no-repeat right center;
//	border:2px solid #000;
//	font-family:"Trebuchet MS",Verdana,sans-serif;
//	font-size:15px;
//	line-height: 18px;
//	text-align:left;
//	color:#000;
//	z-index: 9999;
//}
//
//#iso_ajaxBox.btnClose {
//	background-image: none;
//	cursor: pointer;
//}
//
//#iso_ajaxBox p {
//	margin:1em 0;
//	padding:0;
//}
//
//#iso_ajaxBox .iso_error {
//	padding-left: 34px;
//	background: url("../images/cross-24.png") left center no-repeat;
//}
//
//#iso_ajaxBox .iso_confirm {
//	padding-left: 34px;
//	background: url("../images/tick-24.png") left center no-repeat;
//}
//
//#iso_ajaxBox .iso_info {
//	padding-left: 34px;
//	background: url("../images/information-24.png") left center no-repeat;
//}
//
//#iso_ajaxOverlay {
//	width:100%;
//	height:100%;
//	position:fixed;
//	top:0;
//	left:0;
//	background-color:#000;
//	z-index: 9998;
//	-moz-opacity:0.5;
//	opacity:0.5;
//	_display:none;
//	filter:Alpha(opacity=50);
//}
