/* ===================================================================================================================
   User
   ================================================================================================================ */

// -------------------------------------------------------------------------------------------------------------------
// Registrierung
// -------------------------------------------------------------------------------------------------------------------

.mod_personalData {
	margin-bottom  : $global-margin;
	padding-bottom : $global-padding;
	border-bottom  : 1px solid $black;
}

.mod_registration .fields,
.mod_personalData .fields {
	@include flex-grid-row;
	max-width : none;
	@include grid-remove-row-margin;
}

.mod_registration .widget,
.mod_personalData .widget {
	@include flex-grid-column(6);
}

.mod_registration .widget:nth-child(1),
.mod_personalData .widget:nth-child(1),
.mod_registration .widget:nth-child(10),
.mod_personalData .widget:nth-child(10) {
	@include flex-grid-column(12);
}

// -------------------------------------------------------------------------------------------------------------------

.mod_login .fields,
.mod_lostPassword .fields {
	@include flex-grid-row;
	max-width : none;
	@include grid-remove-row-margin;
}

.mod_login .widget,
.mod_lostPassword .widget {
	@include flex-grid-column(12);
	@include breakpoint(xsmall) {
		@include flex-grid-column(6);
	}
}
