/* ===================================================================================================================
   Buttons
   ================================================================================================================ */

.button,
.submit,
[class*="mod_iso"] .review a.edit,
.page_sidebar-subnavigation a,
.page_sidebar-subnavigation span,
.mod_iso_orderhistory .document_number a {
	margin           : $global-margin 0;
	//padding          : 0.5em 1em 0.25em 1em;
	padding          : 10px 10px 8px 10px;
	font-weight      : $font-weight-2;
	color            : $primary-color;
	//letter-spacing   : $letter-spacing-medium;
	text-transform   : uppercase;
	background-color : transparent;
	border           : 1px solid $primary-color;
}

.page_sidebar-subnavigation span { display : block; }

.mod_iso_productfilter a.clear_filters {
	display        : block;
	margin         : 0 0 $global-margin 0;
	padding-top    : 12px;
	padding-bottom : 11px;
}

.mod_iso_orderhistory .document_number a {
	padding : 4px 2px 2px 2px;
}

.button:focus,
.button:hover,
.submit:focus,
.submit:hover,
[class*="mod_iso"] .review a.edit:focus,
[class*="mod_iso"] .review a.edit:hover,
.page_sidebar-subnavigation a:focus,
.page_sidebar-subnavigation a:hover,
.page_sidebar-subnavigation span,
.mod_iso_orderhistory .document_number a:focus,
.mod_iso_orderhistory .document_number a:hover {
	color            : $white;
	background-color : $secondary-color;
}

// -------------------------------------------------------------------------------------------------------------------

.mod_iso_productreader .submit,
.mod_iso_checkout .submit.confirm {
	//color            : $white;
	//background-color : $secondary-color;
}

.mod_iso_productreader .submit:focus,
.mod_iso_productreader .submit:hover,
.mod_iso_checkout .submit.confirm:focus,
.mod_iso_checkout .submit.confirm:hover {
	//color            : $primary-color;
	//background-color : transparent;
}

// -------------------------------------------------------------------------------------------------------------------

//.page_content-basket-mini .button {
//	font-size : rem-calc(10);
//	@include breakpoint(xsmall) {
//		font-size : rem-calc(14);
//	}
//	@include breakpoint(medium) {
//		font-size : rem-calc(16);
//	}
//}

// -------------------------------------------------------------------------------------------------------------------

.mod_registration .submit {
	margin-top : rem-calc(25);
	@include button-expand(true);
}

// -------------------------------------------------------------------------------------------------------------------

.button_hamburger span {
	width            : 25px;
	height           : 2px;
	background-color : #232323;
	display          : block;
	margin           : 3px auto;
	transition       : all 0.3s ease-in-out;
}

.button_hamburger:focus,
.button_hamburger:hover {
	cursor : pointer;
}

// Todo PF: Problem bei Animation in Verbindung mit OffCanvas (checken) - siehe app.js Zeile 20 ff. (deaktiviert)
//.button_hamburger.is-active {
//	transition       : all 0.3s ease-in-out;
//	transition-delay : 0.6s;
//	transform        : rotate(45deg);
//}
//
//.button_hamburger.is-active span:nth-child(2) {
//	width : 0;
//}
//
//.button_hamburger.is-active span:nth-child(1),
//.button_hamburger.is-active span:nth-child(3) {
//	transition-delay : 0.3s;
//}
//
//.button_hamburger.is-active span:nth-child(1) {
//	transform : translateY(5px);
//}
//
//.button_hamburger.is-active span:nth-child(3) {
//	transform : translateY(-5px) rotate(90deg);
//}

// -------------------------------------------------------------------------------------------------------------------

