// ===================================================================================================================
// Settings - Project
// ===================================================================================================================

//$color-1 : #dfcdac;
//$color-2 : #c9c8c8;
//$color-3 : #f5f4f4;

// Anpassung je nach verwendeten Schriftarten im Projekt erforderlich
// Einbindung der Schriften erfolgt je nach Variante entweder in 'project.scss' und/oder Contao Seitenlayout
$font-weight-0 : 400; // 100 - 300
$font-weight-1 : 400; // 100 - 300
$font-weight-2 : 400; // 400 - 500
$font-weight-3 : 700; // 600 - 700
//$font-weight-4: 800; // 800 - 900

// Überschreibt Foundation Core Einstellungen
$global-weight-normal : $font-weight-1;
$global-weight-bold : $font-weight-3;

$letter-spacing-small : 0.05em;
$letter-spacing-medium : 0.10em;
$letter-spacing-large : 0.2em;

// -------------------------------------------------------------------------------------------------------------------

// Klassen zur Steuerung vertikaler(!) Abstände
// TODO PF: Umbenennen in $margin-y-small etc.?!
$margin-small : 20px;
$margin-medium : 30px;
$margin-large : 80px;
$margin-xlarge : 120px;

.margin-y-large {
	margin-top    : $margin-medium !important;
	margin-bottom : $margin-medium + 30px !important;
	@include breakpoint(medium) {
		margin-top    : $margin-large !important;
		margin-bottom : $margin-large + 30px !important;
	}
}

//$padding-small : 15px;
//$padding-medium : 30px;
//$padding-large : 60px;
//$padding-xlarge : 120px;
//
//.padding-none {
//	padding : 0;
//}

// -------------------------------------------------------------------------------------------------------------------

// Ausgleich Außenabstand Grid. Setzt Zentrierung außer Kraft!
// Anwendung mit vorangestelltem Minus und Wert/2!
$grid-column-gutter-small : map-get($grid-column-gutter, small);
$grid-column-gutter-medium : map-get($grid-column-gutter, medium);

@mixin grid-remove-row-margin {
	margin-left  : -$grid-column-gutter-small/2;
	margin-right : -$grid-column-gutter-small/2;
	@include breakpoint(medium) {
		margin-left  : -$grid-column-gutter-medium/2;
		margin-right : -$grid-column-gutter-medium/2;
	}
}

// Helferklasse 'remove-margin' wird in theme-development erzeugt
// Integration in Array 'row' von DMA Simple Grid Konfiguration

// -------------------------------------------------------------------------------------------------------------------

// Klasse für Zuweisung horizontaler Paddings in Abgleich mit Grid Paddings
@mixin grid-padding-x {
	padding-left  : rem-calc($grid-column-gutter-small) / 2;
	padding-right : rem-calc($grid-column-gutter-small) / 2;
	@include breakpoint(medium) {
		padding-left  : rem-calc($grid-column-gutter-medium) / 2;
		padding-right : rem-calc($grid-column-gutter-medium) / 2;
	}
}

@mixin grid-padding-x-none {
	padding-left  : 0;
	padding-right : 0;
	@include breakpoint(medium) {
		padding-left  : 0;
		padding-right : 0;
	}
}
