/* ====================================================================================================================
   Development
   ================================================================================================================= */
// Temporäre Datei während Entwicklung - Für PRO Betrieb sollten alle Inhalte in separate Module ausgelagert sein!
// --------------------------------------------------------------------------------------------------------------------

// Helferklasse - Integration in Array 'row' von DMA Simple Grid Konfiguration
// SASS Nutzung via mixin 'remove-grid-row-margin' - siehe 'settings-project'
.remove-margin {
	@include grid-remove-row-margin;
}

//.product {
//	@include mui-animation(fade(in));
//	animation-duration : 8s;
//}

