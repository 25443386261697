/* ===================================================================================================================
   RSCE 1-010 - CE Item - Teaser (Template: rsce_1_001_teaser)
   ================================================================================================================ */
//.ce_item-teaser { }
// -------------------------------------------------------------------------------------------------------------------

.ce_item-teaser .columns > div, .ce_item-teaser .columns > a {
	height           : 100%;
	background-color : $light-gray;
	padding          : 1.5rem;
}

.ce_item-teaser .columns > a {
	display : block;
	width   : 100%;
}

.ce_item-teaser .bg-image {
	background-repeat : no-repeat;
	background-size   : cover;
}
