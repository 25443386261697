/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
// Dependencies
@import '_vendor/normalize-scss/sass/normalize';
@import '_vendor/sassy-lists/stylesheets/helpers/missing-dependencies';
@import '_vendor/sassy-lists/stylesheets/helpers/true';
@import '_vendor/sassy-lists/stylesheets/functions/purge';
@import '_vendor/sassy-lists/stylesheets/functions/remove';
@import '_vendor/sassy-lists/stylesheets/functions/replace';
@import '_vendor/sassy-lists/stylesheets/functions/to-list';
// Settings
// import your own `settings` here or
// import and modify the default settings through
// @import 'settings/settings';
// Sass utilities
@import 'util/util';
// Global variables and styles
@import 'global';
// Components
@import 'grid/grid';
@import 'typography/typography';
@import 'forms/forms';
@import 'components/visibility';
@import 'components/float';
@import 'components/button';
@import 'components/button-group';
@import 'components/accordion-menu';
@import 'components/accordion';
@import 'components/badge';
@import 'components/breadcrumbs';
@import 'components/callout';
@import 'components/card';
@import 'components/close-button';
@import 'components/drilldown';
@import 'components/dropdown-menu';
@import 'components/dropdown';
@import 'components/flex';
@import 'components/responsive-embed';
@import 'components/label';
@import 'components/media-object';
@import 'components/menu';
@import 'components/menu-icon';
@import 'components/off-canvas';
@import 'components/orbit';
@import 'components/pagination';
@import 'components/progress-bar';
@import 'components/reveal';
@import 'components/slider';
@import 'components/sticky';
@import 'components/switch';
@import 'components/table';
@import 'components/tabs';
@import 'components/title-bar';
@import 'components/top-bar';
@import 'components/thumbnail';
@import 'components/tooltip';

@mixin foundation-everything($flex: false) {
	@if $flex {
		$global-flexbox : true !global;
	}
	
	@include foundation-global-styles;
	@if not $flex {
		@include foundation-grid;
	} @else {
		@include foundation-flex-grid;
	}
	@include foundation-typography;
	@include foundation-forms;
	@include foundation-button;
	@include foundation-accordion;
	@include foundation-accordion-menu;
	@include foundation-badge;
	@include foundation-breadcrumbs;
	@include foundation-button-group;
	@include foundation-callout;
	@include foundation-card;
	@include foundation-close-button;
	@include foundation-menu;
	@include foundation-menu-icon;
	@include foundation-drilldown-menu;
	@include foundation-dropdown;
	@include foundation-dropdown-menu;
	@include foundation-responsive-embed;
	@include foundation-label;
	@include foundation-media-object;
	@include foundation-off-canvas;
	@include foundation-orbit;
	@include foundation-pagination;
	@include foundation-progress-bar;
	@include foundation-slider;
	@include foundation-sticky;
	@include foundation-reveal;
	@include foundation-switch;
	@include foundation-table;
	@include foundation-tabs;
	@include foundation-thumbnail;
	@include foundation-title-bar;
	@include foundation-tooltip;
	@include foundation-top-bar;
	@include foundation-visibility-classes;
	@include foundation-float-classes;
	
	@if $flex {
		@include foundation-flex-classes;
	}
}
