/* ===================================================================================================================
   RSCE 2-001 - CE Item - Media Teaser (Template: rsce_2_001_media_teaser)
   ================================================================================================================ */
//.ce_item-media-teaser {}
//.ce_item-media-teaser__background {}
//.ce_item-media-teaser__content-wrapper-outside {}
//.ce_item-media-teaser__content-wrapper-inside {}
//.ce_item-media-teaser__headline-wrapper {}
//.ce_item-media-teaser__addition {}
//.ce_item-media-teaser__headline {}
//.ce_item-media-teaser__subheadline
//.ce_item-media-teaser__content {}
//.ce_item-media-teaser__logo {}
//.ce_item-media-teaser__link {}
//.link_scroll-down {}
// -------------------------------------------------------------------------------------------------------------------
.ce_item-media-teaser {
	position           : relative;
	height             : 100vh;
	overflow           : hidden;
	//background         : $black;
	border             : rem-calc($grid-column-gutter-small) solid $white;
	@include breakpoint(medium) {
		border : rem-calc($grid-column-gutter-medium) solid $white;
	}
	@include mui-animation(fade(in));
	animation-duration : 5s;
}

.ce_item-media-teaser__background {
	position        : absolute;
	top             : 0;
	left            : 0;
	right           : 0;
	bottom          : 0;
	overflow        : hidden;
	background      : 50% 50% no-repeat;
	background-size : cover;
}

// HTML5 Video // Mediaelement im Seitenlayout MUSS(!) deaktiviert sein!
// Change this to "object-fit: cover" once all browsers support it
// Link: http://stackoverflow.com/a/20851590
.ce_item-media-teaser__background video {
	position   : absolute;
	top        : 50%;
	left       : 50%;
	min-width  : 100%;
	min-height : 100%;
	transform  : translate(-50%, -50%);
}

// Zentrierung
.ce_item-media-teaser__content-wrapper-outside {
	position   : relative;
	top        : 50%;
	text-align : center;
	transform  : translateY(-50%);
}

.ce_item-media-teaser__content-wrapper-inside {
	padding-left       : 20px;
	padding-right      : 20px;
	@include mui-animation(fade(in));
	animation-duration : 10s;
	
	@include breakpoint(medium) {
		padding-left  : 40px;
		padding-right : 40px;
	}
}

.ce_item-media-teaser__headline-wrapper-outside {
	//max-width        : 600px;
	//margin           : $global-margin auto 0 auto;
	//padding          : 1em;
	//background-color : transparent;
	//border           : 2px solid $white;
}

.ce_item-media-teaser__headline-wrapper-inside {
	//padding          : 1em 2em;
	//background-color : $white;
}

.ce_item-media-teaser__headline,
.ce_item-media-teaser__subheadline {
	//text-transform : uppercase;
	//font-size      : rem-calc(18);
	//font-weight    : $font-weight-2;
}

.ce_item-media-teaser__subheadline {
	//font-size   : rem-calc(12);
	//font-weight : $font-weight-0;
	//padding-top : 0.25rem;
}

//.ce_item-media-teaser__headline:before {
//	display           : block;
//	margin            : rem-calc(32) auto rem-calc(24) auto;
//	height            : 16px;
//	width             : 200px;
//	content           : '';
//	background-size   : 16px 16px;
//	background-repeat : repeat-x;
//	background-image  : url(../img/XXXXXXX.svg);
//}

.ce_item-media-teaser__link a {
	text-align  : center;
	color       : $white;
	padding-top : 3rem;
	display     : block;
	@include breakpoint(medium) { //TODO-PF: Überarbeiten
		position : relative;
		//left     : calc(50% - 16px);
		//bottom   : 30px;
		
	}
}

.ce_item-media-teaser__link a:before {
	@include icon(arrow-down4);
	color     : $white;
	position  : relative;
	font-size : rem_calc(36);
}

// TODO PF: Problem mit Icon Font Mixin lösen !!
// @extend darf nicht in unterschiedlichen @media blocks verwendet werden!
//.link_scroll-down {
//	@include breakpoint(medium) { //TODO-PF: Überarbeiten
//		&:after {
//			@include icon(arrow-down) { // TODO-PF: Abgleich mit passendem Icon im Icon Font
//				font-size : 32px;
//			}
//		}
//	}
//}

