/* ===================================================================================================================
   Page Layout
   ================================================================================================================ */
html {
	height                      : 100%;
	// Disable Tab Highlight Color on Webkit Engines
	-webkit-tap-highlight-color : rgba(255, 255, 255, 0);
}

body { height : 100%;
	font-size : rem-calc(14)
}

// Klasse wird via JS getriggert und verhindert das Inhalte im Hintergrund scrollen
// Im Einsatz z.B. bei aktiver Navigation Fullscreen von CodyHouse oder Nivo Lightbox
body.overflow-hidden { overflow : hidden; }

// -------------------------------------------------------------------------------------------------------------------
// Responsive Sticky Footer - Start
// -------------------------------------------------------------------------------------------------------------------
// ToDo PF: Beide Lösungen funktionieren OOTB nicht in Verbindung mit OffCanvas Bereichen
.page_wrapper {
	height : 100%;
	//display        : flex;
	//flex-direction : column;
}

//.page_content-wrapper-outside { flex : 1; }

// -------------------------------------------------------------------------------------------------------------------
//.page_wrapper {
//	display : table;
//	width   : 100%;
//	height  : 100%;
//}
//
//.page_header,
//.page_teaser,
//.page_footer,
//.page_content-wrapper-outside { display : table-row; }
//
//.page_content-wrapper-outside { height : 100%; }
// -------------------------------------------------------------------------------------------------------------------
// Responsive Sticky Footer - Stop
// -------------------------------------------------------------------------------------------------------------------
.page_column-wrapper {
	position : relative;
	display  : block;
	//table-layout : fixed;
	width    : 100%;
	height   : 100%;
}

// -------------------------------------------------------------------------------------------------------------------
// Page Layout: 0-1-0
// -------------------------------------------------------------------------------------------------------------------
// page-column-1 = main content
// -------------------------------------------------------------------------------------------------------------------
.page_layout-0-1-0 .page_column-1 {
	width  : 100%;
	height : 100%;
}

// TODO-PF: Eventuell Austausch durch Grid System (Float oder Flex)
// -------------------------------------------------------------------------------------------------------------------
// Page Layout: 3-1-0 | Unit-Mix: No | Allowed Units: % | px |(r)em
// If linearized, the sidebar content drops below the main content.
// -------------------------------------------------------------------------------------------------------------------
// page_column-1 => main content | page_column-3 => sidebar content (left)
// -------------------------------------------------------------------------------------------------------------------
@include breakpoint(large) {
	.page_layout-3-1-0 .page_column-1 { float : right; width : 75%; }
	.page_layout-3-1-0 .page_column-3 { margin : 0 75% 0 0; }
	
	.page_layout-3-1-0 .page_content { margin-left : rem-calc(20); }
	.page_layout-3-1-0 .page_sidebar { margin-right : rem-calc(20); }
}

// -------------------------------------------------------------------------------------------------------------------
// Layout variant with fixed sidebar.
// -------------------------------------------------------------------------------------------------------------------
// This layout variant requires that 'page_column-1' isn't completely empty. IE needs at least dummy content (&nbsp;),
// otherwise it doesn't render the element and the alignment of the sidebar within the parents padding area fails.
// -------------------------------------------------------------------------------------------------------------------
//@include breakpoint(large) {
//	.page_layout-3-1-0 .page_column-wrapper {
//		display      : block;
//		width        : auto;
//		overflow     : hidden;
//		padding-left : 250px; // column width
//	}
//
//	.page_layout-3-1-0 .page_column-1 {
//		float    : left;
//		width    : 100%;
//		position : relative;
//	}
//
//	.page_layout-3-1-0 .page_column-3 {
//		float    : left;
//		width    : 250px; // column width
//		right    : 250px; // column width
//		position : relative;
//		margin   : 0 0 0 -100%;
//	}
//
//	.page_layout-3-1-0 .page_content { margin-left : rem-calc(20); }
//	.page_layout-3-1-0 .page_sidebar { margin-right : rem-calc(20); }
//}
// -------------------------------------------------------------------------------------------------------------------
// Page Layout: 0-1-3 | Unit-Mix: No | Allowed Units: % | px |(r)em
// If linearized, the sidebar content drops below the main content.
// -------------------------------------------------------------------------------------------------------------------
// page_column-1 => main content | page_column-3 => sidebar content (right)
// -------------------------------------------------------------------------------------------------------------------
//@include breakpoint(large) {
//	.page_layout-0-1-3 .page_column-1 { float : left; width : 66.666%; }
//	.page_layout-0-1-3 .page_column-3 { margin : 0 0 0 66.666%; }
//
//	.page_layout-0-1-3 .page_content { margin-right : rem-calc(20); }
//	.page_layout-0-1-3 .page_sidebar { margin-left : rem-calc(20); }
//}

// -------------------------------------------------------------------------------------------------------------------
// Layout variant with fixed sidebar.
// -------------------------------------------------------------------------------------------------------------------
// This layout variant requires that 'page-column-1' isn't completely empty. IE needs at least dummy content (&nbsp;),
// otherwise it doesn't render the element and the alignment of the sidebar within the parents padding area fails.
// -------------------------------------------------------------------------------------------------------------------
//@include breakpoint(large) {
//	.page_layout-0-1-3 .page_column-wrapper {
//		display       : block;
//		width         : auto;
//		overflow      : hidden;
//		padding-right : 250px; // column width
//	}
//
//	.page_layout-0-1-3 .page_column-1 {
//		float    : left;
//		width    : 100%;
//		position : relative;
//	}
//
//	.page_layout-0-1-3 .page_column-3 {
//		float    : left;
//		width    : 250px; // column width
//		position : relative;
//		margin   : 0 -250px 0 0; // column width
//	}
//
//	.page_layout-0-1-3 .page_content { margin-right : rem-calc(20); }
//	.page_layout-0-1-3 .page_sidebar { margin-left : rem-calc(20); }
//}
// -------------------------------------------------------------------------------------------------------------------
// Page Layout: 1-3-0 | Unit-Mix: No | Allowed Units: % | px |(r)em
// If linearized, the main content drops below the sidebar content.
// -------------------------------------------------------------------------------------------------------------------
// page_column-1 => sidebar content (left) | page_column-3 => main content
// -------------------------------------------------------------------------------------------------------------------
@include breakpoint(large) {
	.page_layout-1-3-0 .page_column-1 { float : left; width : 25%; }
	.page_layout-1-3-0 .page_column-3 { margin : 0 0 0 25%; }
	
	.page_layout-1-3-0 .page_content { margin-left : rem-calc(20); }
	.page_layout-1-3-0 .page_sidebar { margin-right : rem-calc(20); }
}

// -------------------------------------------------------------------------------------------------------------------
// Page Layout: 0-3-1 | Unit-Mix: No | Allowed Units: % | px |(r)em
// If linearized, the main content drops below the sidebar content.
// -------------------------------------------------------------------------------------------------------------------
// page_column-1 => sidebar content (right) | page_column-3 => main content
// -------------------------------------------------------------------------------------------------------------------
//@include breakpoint(large) {
//	.page_layout-0-3-1 .page_column-1 { float : right; width : 33.333%; }
//	.page_layout-0-3-1 .page_column-3 { margin : 0 33.333% 0 0; }
//
//	.page_layout-0-3-1 .page_content { margin-right : rem-calc(20); }
//	.page_layout-0-3-1 .page_sidebar { margin-left : rem-calc(20); }
//}
// -------------------------------------------------------------------------------------------------------------------
// Page Layout: 2-1-3 | Unit-Mix: No | Allowed Units: % | px
// If linearized, the sidebar content drops below the main content.
// -------------------------------------------------------------------------------------------------------------------
// page_column-1 => main content | page_column-2 => sidebar content (left) | page_column-3 => sidebar content (right)
// -------------------------------------------------------------------------------------------------------------------
//@include breakpoint(large) {
//	.page_layout-2-1-3 .page_column-1 { width : 50%; float : left; margin-left : 25%; }
//	.page_layout-2-1-3 .page_column-2 { width : 25%; float : left; margin-left : -75%; }
//	.page_layout-2-1-3 .page_column-3 { width : 25%; float : right; margin-left : -5px; margin-right : 0; }
//
//	.page_layout-2-1-3 .page_content { margin : 0 rem-calc(20); }
//	.page_layout-2-1-3 .page_sidebar { margin-right : rem-calc(20); }
//	.page_layout-2-1-3 .page_sidebar-right { margin-left : rem-calc(20); }
//}
/* ===================================================================================================================
   Grid - Foundation
   ================================================================================================================ */

// Aus NB-CBS-FS-DEV übernommen

.expanded .row {
	margin-left  : auto;
	margin-right : auto;
	max-width    : 75rem;
}

//.page_content .row {
//	margin-bottom : 1.5em;
//}

@mixin bg-color {
	border-left  : 0.69444rem solid $light-gray;
	border-right : 0.69444rem solid $light-gray;
	@include breakpoint(medium) {
		border-left  : 1.04167rem solid $light-gray;
		border-right : 1.04167rem solid $light-gray;
	}
	padding      : 1.5rem;
	padding-top  : 2rem;
}

.bg-color-lightgrey {
	@include bg-color;
	background-color : $light-gray;
}

//.bg-color-white {
//	@include bg-color;
//	background-color: $white;
//}

.bg-color-primary {
	@include bg-color;
	background-color : $primary-color;
}

.row-one-column > * {
	@include flex-grid-column(12);
}

.row-two-columns > * {
	@include flex-grid-column(12);
	@include breakpoint(large) {
		@include flex-grid-column(6);
	}
}

.row-three-columns > * {
	@include flex-grid-column(12);
	@include breakpoint(medium) {
		@include flex-grid-column(6);
	}
	@include breakpoint(large) {
		@include flex-grid-column(4);
	}
}

.row-four-columns > * {
	@include flex-grid-column(12);
	@include breakpoint(medium) {
		@include flex-grid-column(6);
	}
	@include breakpoint(large) {
		@include flex-grid-column(3);
	}
}

.row-big-small > *:nth-child(odd) {
	@include flex-grid-column(12);
	@include breakpoint(medium) {
		@include flex-grid-column(6);
	}
	@include breakpoint(large) {
		@include flex-grid-column(8);
	}
}

.row-big-small > *:nth-child(even) {
	@include flex-grid-column(12);
	@include breakpoint(medium) {
		@include flex-grid-column(6);
	}
	@include breakpoint(large) {
		@include flex-grid-column(4);
	}
}

.row-small-big > *:nth-child(odd) {
	@include flex-grid-column(12);
	@include breakpoint(medium) {
		@include flex-grid-column(6);
	}
	@include breakpoint(large) {
		@include flex-grid-column(4);
	}
}

.row-small-big > *:nth-child(even) {
	@include flex-grid-column(12);
	@include breakpoint(medium) {
		@include flex-grid-column(6);
	}
	@include breakpoint(large) {
		@include flex-grid-column(8);
	}
}

.remove-grid {
	width     : 100% !important;
	flex      : 0 0 100% !important;
	max-width : none !important;
}

/* ===================================================================================================================
   Miscellaneous - Global
   ================================================================================================================ */

// Contao Core Klasse (Skip Links, Formulare etc.)
// Gleichnamige Foundation Klasse ungeeignet
// Umsetzung nicht ideal, da Screenreader ausgeschlossen
.invisible {
	display    : none !important;
	visibility : hidden !important;
}

// Contao Core Klassen (Floating)
// Breakpoint und Abstand ggf. Anpassen
.float_above {
	margin-bottom : $margin-small;
}

.float_left {
	margin-bottom : $margin-small;
	@include breakpoint(large) {
		float        : left;
		margin-right : $margin-small;
	}
}

.float_right {
	margin-bottom : $margin-small;
	@include breakpoint(large) {
		float       : right;
		margin-left : $margin-small;
	}
}

// Überschreibt die Normalisierung. Im Grid wahrscheinlich zumeist sinnvoll (Abhängig vom Layout)
// Könnte in anderen Fällen aber nicht sinnvoll sein (Text + Bild)
figure { margin : 0; }

// Outline Entfernung
button,
select,
.submit,
.reveal,
a.edit { outline : 0; }

// Fehlermeldung Formulare (ohne bzw. Fallback HTML5 Validierung)
p.error,
.mod_iso_cart .coupon .failed {
	padding     : 0.85em 0.5em;
	font-size   : rem-calc(12);
	font-weight : $font-weight-3;
	color       : $white;
	background  : $alert-color;
}

/* ===================================================================================================================
   Typography - Global
   ================================================================================================================ */

body {
	letter-spacing : $letter-spacing-small;
}

h1, h2, h3, h4, h5, h6,
	//.ce_item-media-teaser__headline,
.ce_item-media-teaser__subheadline {
	letter-spacing : $letter-spacing-small;
	font-weight    : $font-weight-3;
}

h1 {
	padding-top    : 0.5rem;
	font-weight    : $font-weight-0;
	text-transform : uppercase;
	padding-bottom : 1rem;
}

h3 {
	font-weight    : $font-weight-1;
	text-transform : uppercase;
}

p, td, li {
	font-size : rem_calc(14);
}

.reveal ol,
.page_content ol {
	margin-left : 1.75rem;
}

select {
	border : 1px solid $primary-color;
	margin : 0 0 0.5rem;
}

.page_column-1 select {
	border : 1px solid $medium-gray;
	margin : 0 0 1rem;
}

#ctrl_target {
	text-transform : uppercase;
	letter-spacing : $letter-spacing-medium;
}

option {
	text-transform : none !important;
	letter-spacing : 0;
}

/* ===================================================================================================================
   Page Main Elements - Header, Teaser, Content, Sidebar, Footer
   ================================================================================================================ */
// Page Header
// -------------------------------------------------------------------------------------------------------------------
.page_header { }

.top-navigation {
	z-index    : 100;
	position   : fixed;
	top        : 0;
	left       : 0;
	width      : 100%;
	background : $body-background;
	@include breakpoint(large) {
		padding-top : 1rem;
		//padding-bottom : 1rem;
	}
	
}

.top-navigation_logo {
	margin-top : 1.25em;
	z-index    : 100;
	.image_container {
		margin-bottom : 0.5em;
	}
}

.top-navigation_shop {
	//@include flex-grid-column(6);
	//order          : 3;
	//line-height    : 1;
	//padding-bottom : 15px;
	position      : relative;
	margin-top    : -20px;
	margin-bottom : 10px;
	text-align    : right;
	@include breakpoint(large) {
		margin-top    : 0;
		margin-bottom : 0;
	}
}

.top-navigation_shop .link_basket {
	&:after {
		@include icon(cart5) {
			font-size : 14px;
		}
	}
}

// -------------------------------------------------------------------------------------------------------------------

.page_type-home .page_header { }

// -------------------------------------------------------------------------------------------------------------------
// Page Teaser
// -------------------------------------------------------------------------------------------------------------------
.page_teaser {
	position   : relative;
	margin-top : 7em;
	@include breakpoint(large) {
		margin-top : -1.5em;
	}
}

.page_teaser .mod_navigation ul {
	list-style  : none;
	margin-left : 0;
}

.page_teaser .mod_navigation li {
	display        : inline;
	text-transform : uppercase;
	padding-left   : 0.5rem;
}

.page_teaser .mod_navigation li:first-child {
	padding-left : 0;
}

// -------------------------------------------------------------------------------------------------------------------
// Page Content
// -------------------------------------------------------------------------------------------------------------------
.page_wrapper {
	position : relative;
	top      : 0;
	@include breakpoint(large) {
		top : 12em;
	}
}

.page_column-wrapper {
	max-width : $global-width;
	margin    : 0 auto;
	@include grid-padding-x;
	@include breakpoint(medium) {
		margin-top : 45px;
	}
}

.page_type-home .page_column-wrapper,
.page_type-shoplist .page_column-wrapper {
	max-width : none;
	@include grid-padding-x-none;
}

// -------------------------------------------------------------------------------------------------------------------

.page_content { }

.page_content .ce_text a, .page_content .ce_item-intro-text__content a {
	text-decoration : underline;
}

.page_content .ce_text a.button {
	text-decoration : none;
}

.image_container {
	position      : relative;
	margin-bottom : 2em;
}

.ce_image .caption {
	text-transform   : uppercase;
	position         : relative;
	background-color : rgba($black, 1);
	@include breakpoint(medium) {
		position         : absolute;
		background-color : rgba($black, 0.8);
	}
	bottom           : 0;
	color            : $white;
	width            : 100%;
	padding          : 1rem;
	z-index          : 2;
}

// -----------------------------------------
// Hover Effekt für verlinkte Bilder - Start
// -----------------------------------------
//
//.image_container.image_hover a:first-child {
//	display  : block;
//	position : relative;
//	height   : 100%;
//	overflow : hidden;
//}
//
//.image_container.image_hover a:first-child:after {
//	background : rgba($black, 0) none no-repeat scroll center center / 2rem 2rem;
//	content    : "";
//	height     : 100%;
//	left       : 0;
//	position   : absolute;
//	top        : 0;
//	transition : background 0.3s ease 0s;
//	width      : 100%;
//	z-index    : 2;
//}
//
//.image_container.image_hover a:first-child:hover:after {
//	background : rgba($black, .7) no-repeat scroll center center / 2rem 2rem;
//}
//
//.image_container.image_hover a:first-child:before {
//	//z-index    : 3;
//	//position   : absolute;
//	//top        : calc(50% - 12px);
//	//left       : 0;
//	//width      : 100%;
//	//align-self : center;
//	//@include icon(plus3);
//	//color      : $white;
//	//font-size  : 24px;
//	//text-align : center;
//	//opacity    : 0;
//	//transition : opacity 0.3s ease 0s;
//
//	z-index           : 3;
//	position          : absolute;
//	top               : calc(50% - 24px);
//	left              : calc(50% - 24px);
//	height            : 48px;
//	width             : 48px;
//	align-self        : center;
//	content           : '';
//	text-align        : center;
//	background-size   : 48px 48px;
//	background-repeat : no-repeat;
//	//background-image  : url(../img/logo_stilrebellen-sign.svg);
//	opacity           : 0;
//	transition        : opacity 0.3s ease 0s;
//}
//
//.image_container.image_hover a:first-child:hover:before {
//	opacity : 1;
//}
//
//.page_content-teaser-categories-grid-shop .image_container a:first-child:hover:before,
//.page_content-teaser-categories-grid-shop .image_container a:first-child:hover:after {
//	content : none;
//}

.page_content .ce_image a {
	display  : block;
	height   : 100%;
	width    : 100%;
	position : relative;
}

.page_content .image_container {
	//width    : 100%;
	display  : block;
	position : relative;
}

.page_content .image_container a {
	position : relative;
	height   : 100%;
	width    : 100%;
	display  : block;
}

.page_content .image_container a:before {
	content             : '';
	position            : absolute;
	background-color    : rgba($dark-gray, 0.7);
	background-image    : url('../img/signet-atelier-cincin-white.svg');
	background-position : center center;
	background-size     : 40px 40px;
	background-repeat   : no-repeat;
	top                 : 0;
	left                : 0;
	z-index             : 1;
	width               : 100%;
	height              : 100%;
	display             : block;
	opacity             : 0;
	transition          : 0.2s opacity linear;
}

.page_content .image_container a:hover:before {
	opacity : 1;
}

// ----------------------------------------
// Hover Effekt für verlinkte Bilder - Stop
// ----------------------------------------

// -------------------------------------------------------------------------------------------------------------------
// Page Sidebar
// -------------------------------------------------------------------------------------------------------------------

.page_sidebar { }

.page_sidebar .ce_image {
	margin-bottom : 1rem;
}

.page_sidebar-quicknavigation { margin-bottom : $global-margin; }

.page_sidebar-quicknavigation select { margin-bottom : 0; }

.page_sidebar-quicknavigation,
.page_sidebar-basket-mini {
	margin-bottom : $global-margin;
	@include show-for(large);
}

.page_sidebar-isotope-filter,
.page_sidebar-basket-mini {
	padding    : rem-calc(10);
	background : darken(#e9e7e8, 5%);
}

.page_sidebar-isotope-filter {
	margin-bottom : $global-margin;
	//@include breakpoint(large) {
	//	margin-bottom : 0;
	//}
}

.page_sidebar-backlink {
	margin-top    : $global-margin;
	margin-bottom : $global-margin;
	@include breakpoint(large) {
		margin-bottom : 0;
	}
}

.page_sidebar-backlink .backlink {
	line-height    : 1;
	text-transform : uppercase;
	letter-spacing : $letter-spacing-medium;
	@include icon(arrow-left5, before) {
		font-size    : 18px;
		top          : 3px;
		position     : relative;
		margin-right : 0;
	}
}

.page_sidebar-subnavigation {
	margin-top : $global-margin *2;
	//padding-top : $global-padding;
	//border-top  : 1px solid $black;
	
	@include breakpoint(large) {
		margin-top : 0;
		//padding-top : 0;
		//border-top  : 0;
	}
}

// Button Layout ausgelagert nach 'button.scss'
.page_sidebar-subnavigation ul {
	margin-left     : 0;
	list-style-type : none;
}

//.page_sidebar-subnavigation li,
//.page_sidebar-subnavigation a {
//	display: block;
//}

.page_sidebar-subnavigation a {
	@include button-expand(true);
}

.mod_breadcrumb,
.mod_breadcrumb a {
	text-transform : uppercase;
}

// -------------------------------------------------------------------------------------------------------------------
// Page Footer
// -------------------------------------------------------------------------------------------------------------------

.page_footer {
	background  : darken($light-gray, 5%);
	color       : $black;
	margin-top  : 30px;
	padding-top : 0.1rem;
}

.page_footer a {
	color : $black;
}

.page_footer a:focus,
.page_footer a:hover {
	text-decoration : underline;
}

.page_footer a.button:focus,
.page_footer a.button:hover {
	text-decoration : none;
}

.page_footer .row:first-child {
	margin-top : 30px;
}

.page_footer .ce_text {
	margin-bottom : 20px;
	//@include flex-grid-column(12);
}

.page_footer .ce_text:nth-child(3) img {
	max-width : 50px;
}

.page_footer h2 {
	text-transform : uppercase;
	color          : white;
	padding-bottom : 1rem;
}

/* ===================================================================================================================
   Page Type Elements
   ================================================================================================================ */
// .page_type-404 {}
// .page_type-403 {}
// .page_type-home {}
// .page_type-newslist {}
// .page_type-newsreader {}
// .page_type-eventlist {}
// .page_type-eventreader {}
// .page_type-shoplist {}
// .page_type-shopreader {}
// .page_type-imprint {}
// .page_type-contact {}
// .page_type-request-confirmation {}
// .page_type-testpage {}
// .page_type-lookbook {}
// ...
// -------------------------------------------------------------------------------------------------------------------

/* ===================================================================================================================
   Content Type Elements
   ================================================================================================================ */
// .page_content-[xxx]

.content_type-social .social-links {
	float : right;
}

.content_type-social a {
	@include icon(facebook2, before);
	font-size : rem-calc(20);
	&:hover, &:focus, &:active {
		text-decoration : none;
	}
}

.content_type-social a span {
	text-indent : -9999px;
	display     : inline-block;
}

.content_type-social a.pinterest {
	@include icon(pinterest, before);
	font-size : rem-calc(22);
}

.content_type-social a.instagram {
	@include icon(instagram, before);
}

.content_type-copyright p {
	font-size : rem_calc(12);
}
