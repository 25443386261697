/* ===================================================================================================================
   Navigation - Foundation
   ================================================================================================================ */

.no-js {
	@include breakpoint(small only) {
		.top-bar {
			display : none;
		}
	}
	
	@include breakpoint(medium) {
		.title-bar {
			display : none;
		}
	}
}

.logo {
	@include breakpoint(large) {
		@include flex-grid-column(4);
	}
}

.nav-wrapper {
	//width : 100%;
	//@include breakpoint(large) {
	//	@include flex-grid-column(8);
	//}
}

.title-bar {
	position         : relative;
	z-index          : 999;
	width            : 100%;
	background-color : $black;
	padding          : 0.5rem;
}

.top-bar {
	background-color : transparent;
	position         : relative;
	z-index          : 999;
	padding          : 0;
	width            : 100%;
	@include breakpoint(large) {
		padding-top : 20px;
		float       : right;
	}
	
}

.title-bar .menu-icon {
	margin : 0 auto;
}

.menu-icon:after {
	box-shadow : 0 6px 0 $white, 0 12px 0 $white;
}

.menu-icon:hover:after {
	box-shadow : 0 6px 0 $white, 0 12px 0 $white;
}

.top-bar .top-bar-right {
	width : 100%;
	@include breakpoint(large) {
		position     : relative;
		margin-right : 20px;
	}
}

.top-bar a {
	color          : $black;
	text-transform : uppercase;
	//letter-spacing: $letter-spacing-medium;
	padding        : 0.7rem 1rem;
	@include breakpoint(large) {
		//color: $primary-color;
		font-size : rem-calc(14);
	}
}

.top-bar a.active, .top-bar a.trail {
	background-color : transparent;
	@include breakpoint(large) {
		color : $primary-color;
	}
	
}

.top-bar .menu.nested {
	background  : $black;
	margin-left : 0;
	@include breakpoint(large) {
		box-shadow : 2px 2px 7px 0 rgba($black, 0.2);
		background : $dark-gray;
	}
}

.top-bar ul {
	
	@include breakpoint(large) {
		float : right;
	}
}

.top-bar ul ul a {
	color          : $white;
	text-transform : none;
	letter-spacing : $letter-spacing-small;
	padding        : 0.7rem 1rem;
	border-bottom  : 1px solid rgba($white, 0.05);
	@include breakpoint(large) {
		border-bottom : 0px solid rgba($white, 0.05);
	}
}

.top-bar ul ul a.active,
.top-bar ul ul a.trail {
	background-color : darken($dark-gray, 10%);
	color            : $white;
}

.top-bar ul ul a:hover,
.top-bar ul ul a:focus {
	
	@include breakpoint(large) {
		background-color : darken($dark-gray, 50%);
	}
}

.top-bar ul.vertical.dropdown {
	background : transparent;
}

.is-dropdown-submenu {
	min-width : 270px;
	border    : 0;
}

.dropdown.menu.vertical.dropdown > li.is-dropdown-submenu-parent > a:after,
.is-accordion-submenu-parent > a:after {
	border-color : $white transparent transparent;
	border       : 0 solid;
}

.dropdown.menu.vertical.dropdown > li.is-dropdown-submenu-parent > a:after,
.is-accordion-submenu-parent > a:after {
	@include icon(arrow-down2);
	color      : $white;
	font-size  : .5rem;
	right      : 10px;
	margin-top : 2px;
	position   : absolute;
	width      : 8px;
	height     : 8px;
	@include breakpoint(large) {
		color : $primary-color;
		top   : 11px;
		right : 8px;
		width : 8px;
	}
}

.top-bar .accordion-menu {
	background-color : darken($dark-gray, 80%);
	color            : $white;
}

.top-bar .accordion-menu li a {
	color : $white;
}

.top-bar .accordion-menu .is-accordion-submenu-item a {
	padding-left : 25px;
}


