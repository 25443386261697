/* ===================================================================================================================
   RSCE 2-001 - CE Item - Orbit Slider (Template: rsce-2-010-orbit-slider)
   ================================================================================================================ */

.orbit {
	position : relative;
}

.orbit-caption {
	display : none;
}

.ce_orbit_slider.slider-background li {
	height              : 100vh;
	background-repeat   : no-repeat;
	background-position : center;
	background-size     : cover;
}

.ce_orbit_slider.slider-background ul {
	height : 100vh !important;
}

.ce_orbit_slider .main-caption {
	position         : relative;
	color            : $white;
	bottom           : 0;
	right            : 0;
	background-color : rgba($black, 1);
	padding          : 1rem 1.5rem 0.5rem 1.5rem;
	width            : 100%;
	@include breakpoint(medium) {
		position         : absolute;
		bottom           : 15%;
		width            : auto;
		background-color : rgba($black, 0.8);
	}
}

.ce_orbit_slider .main-caption h3 {
	text-transform : uppercase;
	font-size      : rem_calc(14);
	font-weight    : $font-weight-1;
	@include breakpoint(medium) {
		font-size : rem_calc(18);
	}
}

//.ce_orbit_slider .orbit-bullets {
//	position   : relative;
//	text-align : center;
//	width      : 100%;
//
//}
