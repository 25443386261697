@charset "UTF-8";
/*!
======================================================================================================================
   Projekt: Buehler Silberschmuck - Webshop Schwaermetall
   -------------------------------------------------------------------------------------------------------------------
   Realisierung: Neubert Werbung e.K. - https://www.neubert-werbung.de | Contao Service - https://contao-service.de
======================================================================================================================
*/
/* ===================================================================================================================
   Settings - Fonts
   ================================================================================================================ */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/LatoLatin-Light.woff2") format("woff2"), url("../fonts/LatoLatin-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/LatoLatin-LightItalic.woff2") format("woff2"), url("../fonts/LatoLatin-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/LatoLatin-Regular.woff2") format("woff2"), url("../fonts/LatoLatin-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/LatoLatin-Italic.woff2") format("woff2"), url("../fonts/LatoLatin-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/LatoLatin-Medium.woff2") format("woff2"), url("../fonts/LatoLatin-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/LatoLatin-Bold.woff2") format("woff2"), url("../fonts/LatoLatin-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Icons";
  src: url("../fonts/icomoon.woff2") format("woff2"), url("../fonts/icomoon.woff") format("woff"), url("../fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

i,
.top-navigation_shop .link_basket:after,
.page_sidebar-backlink .backlink:before,
.content_type-social a:before,
.content_type-social a.pinterest:before,
.content_type-social a.instagram:before,
.dropdown.menu.vertical.dropdown > li.is-dropdown-submenu-parent > a:after,
.is-accordion-submenu-parent > a:after,
.mod_iso_productreader .property_status-ordered span:before,
.basket-maxi_content .remove a:before,
.page_content-basket-mini h2:before,
.page_content-basket-mini .remove a:after,
.page_content-basket-mini .coupon h2:before,
.ce_accordion .toggler:before,
.ce_accordion .toggler:after,
.ce_accordion .toggler.active:after,
.ce_item-media-teaser__link a:before,
[class^="icon_"],
[class*=" icon_"] {
  font-family: "Icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon_instagram {
  content: "";
}

.icon_pinterest {
  content: "";
}

.icon_home {
  content: "";
}

.icon_home2 {
  content: "";
}

.icon_home5 {
  content: "";
}

.icon_newspaper {
  content: "";
}

.icon_magazine {
  content: "";
}

.icon_image2 {
  content: "";
}

.icon_image4 {
  content: "";
}

.icon_image5 {
  content: "";
}

.icon_camera {
  content: "";
}

.icon_camera2 {
  content: "";
}

.icon_play {
  content: "";
}

.icon_film3 {
  content: "";
}

.icon_bullhorn {
  content: "";
}

.icon_megaphone {
  content: "";
}

.icon_new {
  content: "";
}

.icon_connection {
  content: "";
}

.icon_price-tag2 {
  content: "";
}

.icon_price-tags {
  content: "";
}

.icon_price-tag3 {
  content: "";
}

.icon_price-tags2 {
  content: "";
}

.icon_price-tag4 {
  content: "";
}

.icon_price-tags3 {
  content: "";
}

.icon_price-tag5 {
  content: "";
}

.icon_price-tags4 {
  content: "";
}

.icon_cart4 {
  content: "";
}

.icon_cart5 {
  content: "";
}

.icon_coin-euro {
  content: "";
}

.icon_credit-card {
  content: "";
}

.icon_credit-card2 {
  content: "";
}

.icon_calculator2 {
  content: "";
}

.icon_phone {
  content: "";
}

.icon_phone2 {
  content: "";
}

.icon_phone-wave {
  content: "";
}

.icon_at-sign {
  content: "";
}

.icon_envelop {
  content: "";
}

.icon_envelop2 {
  content: "";
}

.icon_envelop3 {
  content: "";
}

.icon_location {
  content: "";
}

.icon_location2 {
  content: "";
}

.icon_location3 {
  content: "";
}

.icon_location4 {
  content: "";
}

.icon_location5 {
  content: "";
}

.icon_location6 {
  content: "";
}

.icon_calendar2 {
  content: "";
}

.icon_calendar3 {
  content: "";
}

.icon_calendar4 {
  content: "";
}

.icon_printer4 {
  content: "";
}

.icon_quotes-left {
  content: "";
}

.icon_quotes-right {
  content: "";
}

.icon_quotes-left2 {
  content: "";
}

.icon_quotes-right2 {
  content: "";
}

.icon_search {
  content: "";
}

.icon_search3 {
  content: "";
}

.icon_lamp7 {
  content: "";
}

.icon_lamp8 {
  content: "";
}

.icon_clipboard {
  content: "";
}

.icon_clipboard2 {
  content: "";
}

.icon_clipboard3 {
  content: "";
}

.icon_clipboard4 {
  content: "";
}

.icon_clipboard5 {
  content: "";
}

.icon_clipboard6 {
  content: "";
}

.icon_more {
  content: "";
}

.icon_more2 {
  content: "";
}

.icon_grid {
  content: "";
}

.icon_grid2 {
  content: "";
}

.icon_grid3 {
  content: "";
}

.icon_grid4 {
  content: "";
}

.icon_grid5 {
  content: "";
}

.icon_grid6 {
  content: "";
}

.icon_menu {
  content: "";
}

.icon_circle-small {
  content: "";
}

.icon_menu3 {
  content: "";
}

.icon_menu4 {
  content: "";
}

.icon_menu5 {
  content: "";
}

.icon_menu6 {
  content: "";
}

.icon_menu7 {
  content: "";
}

.icon_menu8 {
  content: "";
}

.icon_menu9 {
  content: "";
}

.icon_menu10 {
  content: "";
}

.icon_link {
  content: "";
}

.icon_link2 {
  content: "";
}

.icon_link3 {
  content: "";
}

.icon_link5 {
  content: "";
}

.icon_star-empty2 {
  content: "";
}

.icon_star-full {
  content: "";
}

.icon_star-empty3 {
  content: "";
}

.icon_thumbs-up {
  content: "";
}

.icon_thumbs-up2 {
  content: "";
}

.icon_thumbs-down {
  content: "";
}

.icon_thumbs-down2 {
  content: "";
}

.icon_warning {
  content: "";
}

.icon_warning2 {
  content: "";
}

.icon_notification {
  content: "";
}

.icon_notification2 {
  content: "";
}

.icon_question3 {
  content: "";
}

.icon_question4 {
  content: "";
}

.icon_plus {
  content: "";
}

.icon_minus {
  content: "";
}

.icon_plus2 {
  content: "";
}

.icon_minus2 {
  content: "";
}

.icon_plus3 {
  content: "";
}

.icon_minus3 {
  content: "";
}

.icon_plus-circle {
  content: "";
}

.icon_minus-circle {
  content: "";
}

.icon_plus-circle2 {
  content: "";
}

.icon_minus-circle2 {
  content: "";
}

.icon_info {
  content: "";
}

.icon_info2 {
  content: "";
}

.icon_cancel-circle {
  content: "";
}

.icon_cancel-circle2 {
  content: "";
}

.icon_cancel-square {
  content: "";
}

.icon_cancel-square2 {
  content: "";
}

.icon_cancel {
  content: "";
}

.icon_cross {
  content: "";
}

.icon_cross2 {
  content: "";
}

.icon_cross3 {
  content: "";
}

.icon_checkmark {
  content: "";
}

.icon_checkmark2 {
  content: "";
}

.icon_checkmark3 {
  content: "";
}

.icon_checkmark4 {
  content: "";
}

.icon_checkmark5 {
  content: "";
}

.icon_enter {
  content: "";
}

.icon_exit {
  content: "";
}

.icon_play4 {
  content: "";
}

.icon_arrow-up {
  content: "";
}

.icon_arrow-up2 {
  content: "";
}

.icon_arrow-up3 {
  content: "";
}

.icon_arrow-up4 {
  content: "";
}

.icon_arrow-right {
  content: "";
}

.icon_arrow-right2 {
  content: "";
}

.icon_arrow-right3 {
  content: "";
}

.icon_arrow-right4 {
  content: "";
}

.icon_arrow-down {
  content: "";
}

.icon_arrow-down2 {
  content: "";
}

.icon_arrow-down3 {
  content: "";
}

.icon_arrow-down4 {
  content: "";
}

.icon_arrow-left {
  content: "";
}

.icon_arrow-left2 {
  content: "";
}

.icon_arrow-left3 {
  content: "";
}

.icon_arrow-left4 {
  content: "";
}

.icon_arrow-up5 {
  content: "";
}

.icon_arrow-right5 {
  content: "";
}

.icon_arrow-down5 {
  content: "";
}

.icon_arrow-left5 {
  content: "";
}

.icon_arrow-up6 {
  content: "";
}

.icon_arrow-right6 {
  content: "";
}

.icon_arrow-down6 {
  content: "";
}

.icon_arrow-left6 {
  content: "";
}

.icon_arrow-up7 {
  content: "";
}

.icon_arrow-right7 {
  content: "";
}

.icon_arrow-down7 {
  content: "";
}

.icon_arrow-left7 {
  content: "";
}

.icon_arrow-up-left3 {
  content: "";
}

.icon_arrow-up8 {
  content: "";
}

.icon_arrow-up-right3 {
  content: "";
}

.icon_arrow-right8 {
  content: "";
}

.icon_arrow-down-right3 {
  content: "";
}

.icon_arrow-down8 {
  content: "";
}

.icon_arrow-down-left3 {
  content: "";
}

.icon_arrow-left8 {
  content: "";
}

.icon_circle-up2 {
  content: "";
}

.icon_circle-right2 {
  content: "";
}

.icon_circle-down2 {
  content: "";
}

.icon_circle-left2 {
  content: "";
}

.icon_circle-up3 {
  content: "";
}

.icon_circle-right3 {
  content: "";
}

.icon_circle-down3 {
  content: "";
}

.icon_circle-left3 {
  content: "";
}

.icon_circle-up4 {
  content: "";
}

.icon_circle-right4 {
  content: "";
}

.icon_circle-down4 {
  content: "";
}

.icon_circle-left4 {
  content: "";
}

.icon_square-up {
  content: "";
}

.icon_square-right {
  content: "";
}

.icon_square-down {
  content: "";
}

.icon_square-left {
  content: "";
}

.icon_arrow-up9 {
  content: "";
}

.icon_arrow-right9 {
  content: "";
}

.icon_arrow-down9 {
  content: "";
}

.icon_arrow-left9 {
  content: "";
}

.icon_arrow-up12 {
  content: "";
}

.icon_arrow-right12 {
  content: "";
}

.icon_arrow-down12 {
  content: "";
}

.icon_arrow-left12 {
  content: "";
}

.icon_arrow-up14 {
  content: "";
}

.icon_arrow-right14 {
  content: "";
}

.icon_arrow-down14 {
  content: "";
}

.icon_arrow-left14 {
  content: "";
}

.icon_circle-up5 {
  content: "";
}

.icon_circle-right5 {
  content: "";
}

.icon_circle-down5 {
  content: "";
}

.icon_circle-left5 {
  content: "";
}

.icon_circle-up6 {
  content: "";
}

.icon_circle-right6 {
  content: "";
}

.icon_circle-down6 {
  content: "";
}

.icon_circle-left6 {
  content: "";
}

.icon_arrow-up15 {
  content: "";
}

.icon_arrow-right15 {
  content: "";
}

.icon_arrow-down15 {
  content: "";
}

.icon_arrow-left15 {
  content: "";
}

.icon_arrow-up16 {
  content: "";
}

.icon_arrow-right16 {
  content: "";
}

.icon_arrow-down16 {
  content: "";
}

.icon_arrow-left16 {
  content: "";
}

.icon_checkbox-checked {
  content: "";
}

.icon_checkbox-unchecked {
  content: "";
}

.icon_checkbox-partial {
  content: "";
}

.icon_square {
  content: "";
}

.icon_diamond3 {
  content: "";
}

.icon_diamond4 {
  content: "";
}

.icon_checkbox-checked2 {
  content: "";
}

.icon_checkbox-unchecked2 {
  content: "";
}

.icon_radio-checked {
  content: "";
}

.icon_radio-checked2 {
  content: "";
}

.icon_radio-unchecked {
  content: "";
}

.icon_checkmark-circle {
  content: "";
}

.icon_circle {
  content: "";
}

.icon_circle2 {
  content: "";
}

.icon_filter {
  content: "";
}

.icon_filter2 {
  content: "";
}

.icon_filter3 {
  content: "";
}

.icon_filter4 {
  content: "";
}

.icon_new-tab {
  content: "";
}

.icon_new-tab2 {
  content: "";
}

.icon_share3 {
  content: "";
}

.icon_amazon {
  content: "";
}

.icon_google2 {
  content: "";
}

.icon_google-plus {
  content: "";
}

.icon_google-plus2 {
  content: "";
}

.icon_facebook {
  content: "";
}

.icon_facebook2 {
  content: "";
}

.icon_twitter {
  content: "";
}

.icon_youtube {
  content: "";
}

.icon_youtube2 {
  content: "";
}

.icon_xing {
  content: "";
}

.icon_xing2 {
  content: "";
}

.icon_file-pdf {
  content: "";
}

.margin-y-large {
  margin-top: 30px !important;
  margin-bottom: 60px !important;
}

@media print, screen and (min-width: 40em) {
  .margin-y-large {
    margin-top: 80px !important;
    margin-bottom: 110px !important;
  }
}

/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

.foundation-mq {
  font-family: "small=0em&xsmall=30em&medium=40em&large=64em&xlarge=75em&xxlarge=100em";
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: #e9e7e8;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0;
}

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0;
}

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important;
}

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
}

[data-whatinput='mouse'] button {
  outline: 0;
}

pre {
  overflow: auto;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

@media screen and (max-width: 29.9375em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0em), screen and (min-width: 30em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 30em) {
  .hide-for-xsmall {
    display: none !important;
  }
}

@media screen and (max-width: 29.9375em) {
  .show-for-xsmall {
    display: none !important;
  }
}

@media screen and (min-width: 30em) and (max-width: 39.9375em) {
  .hide-for-xsmall-only {
    display: none !important;
  }
}

@media screen and (max-width: 29.9375em), screen and (min-width: 40em) {
  .show-for-xsmall-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}

@media screen and (min-width: 75em) {
  .hide-for-xlarge {
    display: none !important;
  }
}

@media screen and (max-width: 74.9375em) {
  .show-for-xlarge {
    display: none !important;
  }
}

@media screen and (min-width: 75em) and (max-width: 99.9375em) {
  .hide-for-xlarge-only {
    display: none !important;
  }
}

@media screen and (max-width: 74.9375em), screen and (min-width: 100em) {
  .show-for-xlarge-only {
    display: none !important;
  }
}

@media screen and (min-width: 100em) {
  .hide-for-xxlarge {
    display: none !important;
  }
}

@media screen and (max-width: 99.9375em) {
  .show-for-xxlarge {
    display: none !important;
  }
}

@media screen and (min-width: 100em) {
  .hide-for-xxlarge-only {
    display: none !important;
  }
}

@media screen and (max-width: 99.9375em) {
  .show-for-xxlarge-only {
    display: none !important;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}

@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important;
  }
}

@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}

@media screen and (orientation: landscape) {
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
}

@media screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.clearfix::after {
  clear: both;
}

.align-right {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.align-self-bottom {
  align-self: flex-end;
}

.align-middle {
  align-items: center;
}

.align-self-middle {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

.small-order-1 {
  order: 1;
}

.small-order-2 {
  order: 2;
}

.small-order-3 {
  order: 3;
}

.small-order-4 {
  order: 4;
}

.small-order-5 {
  order: 5;
}

.small-order-6 {
  order: 6;
}

@media print, screen and (min-width: 30em) {
  .xsmall-order-1 {
    order: 1;
  }
  .xsmall-order-2 {
    order: 2;
  }
  .xsmall-order-3 {
    order: 3;
  }
  .xsmall-order-4 {
    order: 4;
  }
  .xsmall-order-5 {
    order: 5;
  }
  .xsmall-order-6 {
    order: 6;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1;
  }
  .medium-order-2 {
    order: 2;
  }
  .medium-order-3 {
    order: 3;
  }
  .medium-order-4 {
    order: 4;
  }
  .medium-order-5 {
    order: 5;
  }
  .medium-order-6 {
    order: 6;
  }
}

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1;
  }
  .large-order-2 {
    order: 2;
  }
  .large-order-3 {
    order: 3;
  }
  .large-order-4 {
    order: 4;
  }
  .large-order-5 {
    order: 5;
  }
  .large-order-6 {
    order: 6;
  }
}

@media screen and (min-width: 75em) {
  .xlarge-order-1 {
    order: 1;
  }
  .xlarge-order-2 {
    order: 2;
  }
  .xlarge-order-3 {
    order: 3;
  }
  .xlarge-order-4 {
    order: 4;
  }
  .xlarge-order-5 {
    order: 5;
  }
  .xlarge-order-6 {
    order: 6;
  }
}

@media screen and (min-width: 100em) {
  .xxlarge-order-1 {
    order: 1;
  }
  .xxlarge-order-2 {
    order: 2;
  }
  .xxlarge-order-3 {
    order: 3;
  }
  .xxlarge-order-4 {
    order: 4;
  }
  .xxlarge-order-5 {
    order: 5;
  }
  .xxlarge-order-6 {
    order: 6;
  }
}

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

.row .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

@media print, screen and (min-width: 30em) {
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
}

@media print, screen and (min-width: 40em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}

@media screen and (min-width: 75em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}

@media screen and (min-width: 100em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}

.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}

.row.expanded {
  max-width: none;
}

.row:not(.expanded) .row {
  max-width: none;
}

.row.collapse > .column, .row.collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.row.is-collapse-child,
.row.collapse > .column > .row,
.row.collapse > .columns > .row {
  margin-right: 0;
  margin-left: 0;
}

.column, .columns {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial;
}

@media print, screen and (min-width: 40em) {
  .column, .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.column.row.row, .row.row.columns {
  float: none;
  display: block;
}

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.flex-container {
  display: flex;
}

.flex-child-auto {
  flex: 1 1 auto;
}

.flex-child-grow {
  flex: 1 0 auto;
}

.flex-child-shrink {
  flex: 0 1 auto;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-row-reverse {
  flex-direction: row-reverse;
}

.flex-dir-column {
  flex-direction: column;
}

.flex-dir-column-reverse {
  flex-direction: column-reverse;
}

.small-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.small-offset-1 {
  margin-left: 8.33333%;
}

.small-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-offset-2 {
  margin-left: 16.66667%;
}

.small-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.small-offset-4 {
  margin-left: 33.33333%;
}

.small-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-offset-5 {
  margin-left: 41.66667%;
}

.small-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.small-offset-7 {
  margin-left: 58.33333%;
}

.small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.small-offset-8 {
  margin-left: 66.66667%;
}

.small-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.small-offset-10 {
  margin-left: 83.33333%;
}

.small-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-offset-11 {
  margin-left: 91.66667%;
}

.small-order-1 {
  order: 1;
}

.small-order-2 {
  order: 2;
}

.small-order-3 {
  order: 3;
}

.small-order-4 {
  order: 4;
}

.small-order-5 {
  order: 5;
}

.small-order-6 {
  order: 6;
}

.small-up-1 {
  flex-wrap: wrap;
}

.small-up-1 > .column, .small-up-1 > .columns {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-up-2 {
  flex-wrap: wrap;
}

.small-up-2 > .column, .small-up-2 > .columns {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-up-3 {
  flex-wrap: wrap;
}

.small-up-3 > .column, .small-up-3 > .columns {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.small-up-4 {
  flex-wrap: wrap;
}

.small-up-4 > .column, .small-up-4 > .columns {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-up-5 {
  flex-wrap: wrap;
}

.small-up-5 > .column, .small-up-5 > .columns {
  flex: 0 0 20%;
  max-width: 20%;
}

.small-up-6 {
  flex-wrap: wrap;
}

.small-up-6 > .column, .small-up-6 > .columns {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.small-up-7 {
  flex-wrap: wrap;
}

.small-up-7 > .column, .small-up-7 > .columns {
  flex: 0 0 14.28571%;
  max-width: 14.28571%;
}

.small-up-8 {
  flex-wrap: wrap;
}

.small-up-8 > .column, .small-up-8 > .columns {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

@media print, screen and (min-width: 30em) {
  .xsmall-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .xsmall-offset-0 {
    margin-left: 0%;
  }
  .xsmall-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .xsmall-offset-1 {
    margin-left: 8.33333%;
  }
  .xsmall-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xsmall-offset-2 {
    margin-left: 16.66667%;
  }
  .xsmall-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .xsmall-offset-3 {
    margin-left: 25%;
  }
  .xsmall-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .xsmall-offset-4 {
    margin-left: 33.33333%;
  }
  .xsmall-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xsmall-offset-5 {
    margin-left: 41.66667%;
  }
  .xsmall-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .xsmall-offset-6 {
    margin-left: 50%;
  }
  .xsmall-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .xsmall-offset-7 {
    margin-left: 58.33333%;
  }
  .xsmall-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xsmall-offset-8 {
    margin-left: 66.66667%;
  }
  .xsmall-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .xsmall-offset-9 {
    margin-left: 75%;
  }
  .xsmall-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .xsmall-offset-10 {
    margin-left: 83.33333%;
  }
  .xsmall-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xsmall-offset-11 {
    margin-left: 91.66667%;
  }
  .xsmall-order-1 {
    order: 1;
  }
  .xsmall-order-2 {
    order: 2;
  }
  .xsmall-order-3 {
    order: 3;
  }
  .xsmall-order-4 {
    order: 4;
  }
  .xsmall-order-5 {
    order: 5;
  }
  .xsmall-order-6 {
    order: 6;
  }
  .xsmall-up-1 {
    flex-wrap: wrap;
  }
  .xsmall-up-1 > .column, .xsmall-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xsmall-up-2 {
    flex-wrap: wrap;
  }
  .xsmall-up-2 > .column, .xsmall-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xsmall-up-3 {
    flex-wrap: wrap;
  }
  .xsmall-up-3 > .column, .xsmall-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .xsmall-up-4 {
    flex-wrap: wrap;
  }
  .xsmall-up-4 > .column, .xsmall-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xsmall-up-5 {
    flex-wrap: wrap;
  }
  .xsmall-up-5 > .column, .xsmall-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xsmall-up-6 {
    flex-wrap: wrap;
  }
  .xsmall-up-6 > .column, .xsmall-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .xsmall-up-7 {
    flex-wrap: wrap;
  }
  .xsmall-up-7 > .column, .xsmall-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .xsmall-up-8 {
    flex-wrap: wrap;
  }
  .xsmall-up-8 > .column, .xsmall-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media print, screen and (min-width: 30em) and (min-width: 30em) {
  .xsmall-expand {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 30em) {
  .xsmall-flex-dir-row {
    flex-direction: row;
  }
  .xsmall-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .xsmall-flex-dir-column {
    flex-direction: column;
  }
  .xsmall-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
  .xsmall-flex-child-auto {
    flex: 1 1 auto;
  }
  .xsmall-flex-child-grow {
    flex: 1 0 auto;
  }
  .xsmall-flex-child-shrink {
    flex: 0 1 auto;
  }
}

.row.xsmall-unstack > .column, .row.xsmall-unstack > .columns {
  flex: 0 0 100%;
}

@media print, screen and (min-width: 30em) {
  .row.xsmall-unstack > .column, .row.xsmall-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 30em) {
  .xsmall-collapse > .column, .xsmall-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .xsmall-uncollapse > .column, .xsmall-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .medium-offset-1 {
    margin-left: 8.33333%;
  }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-offset-2 {
    margin-left: 16.66667%;
  }
  .medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .medium-offset-4 {
    margin-left: 33.33333%;
  }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-offset-5 {
    margin-left: 41.66667%;
  }
  .medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .medium-offset-7 {
    margin-left: 58.33333%;
  }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .medium-offset-8 {
    margin-left: 66.66667%;
  }
  .medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .medium-offset-10 {
    margin-left: 83.33333%;
  }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.66667%;
  }
  .medium-order-1 {
    order: 1;
  }
  .medium-order-2 {
    order: 2;
  }
  .medium-order-3 {
    order: 3;
  }
  .medium-order-4 {
    order: 4;
  }
  .medium-order-5 {
    order: 5;
  }
  .medium-order-6 {
    order: 6;
  }
  .medium-up-1 {
    flex-wrap: wrap;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-up-2 {
    flex-wrap: wrap;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-up-3 {
    flex-wrap: wrap;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .medium-up-4 {
    flex-wrap: wrap;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-up-5 {
    flex-wrap: wrap;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .medium-up-6 {
    flex-wrap: wrap;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .medium-up-7 {
    flex-wrap: wrap;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .medium-up-8 {
    flex-wrap: wrap;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-flex-dir-row {
    flex-direction: row;
  }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .medium-flex-dir-column {
    flex-direction: column;
  }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
  .medium-flex-child-auto {
    flex: 1 1 auto;
  }
  .medium-flex-child-grow {
    flex: 1 0 auto;
  }
  .medium-flex-child-shrink {
    flex: 0 1 auto;
  }
}

.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%;
}

@media print, screen and (min-width: 40em) {
  .row.medium-unstack > .column, .row.medium-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .large-offset-1 {
    margin-left: 8.33333%;
  }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-offset-2 {
    margin-left: 16.66667%;
  }
  .large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .large-offset-4 {
    margin-left: 33.33333%;
  }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-offset-5 {
    margin-left: 41.66667%;
  }
  .large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .large-offset-7 {
    margin-left: 58.33333%;
  }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .large-offset-8 {
    margin-left: 66.66667%;
  }
  .large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .large-offset-10 {
    margin-left: 83.33333%;
  }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.66667%;
  }
  .large-order-1 {
    order: 1;
  }
  .large-order-2 {
    order: 2;
  }
  .large-order-3 {
    order: 3;
  }
  .large-order-4 {
    order: 4;
  }
  .large-order-5 {
    order: 5;
  }
  .large-order-6 {
    order: 6;
  }
  .large-up-1 {
    flex-wrap: wrap;
  }
  .large-up-1 > .column, .large-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-up-2 {
    flex-wrap: wrap;
  }
  .large-up-2 > .column, .large-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-up-3 {
    flex-wrap: wrap;
  }
  .large-up-3 > .column, .large-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .large-up-4 {
    flex-wrap: wrap;
  }
  .large-up-4 > .column, .large-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-up-5 {
    flex-wrap: wrap;
  }
  .large-up-5 > .column, .large-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .large-up-6 {
    flex-wrap: wrap;
  }
  .large-up-6 > .column, .large-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .large-up-7 {
    flex-wrap: wrap;
  }
  .large-up-7 > .column, .large-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-8 > .column, .large-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 64em) {
  .large-flex-dir-row {
    flex-direction: row;
  }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .large-flex-dir-column {
    flex-direction: column;
  }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
  .large-flex-child-auto {
    flex: 1 1 auto;
  }
  .large-flex-child-grow {
    flex: 1 0 auto;
  }
  .large-flex-child-shrink {
    flex: 0 1 auto;
  }
}

.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%;
}

@media print, screen and (min-width: 64em) {
  .row.large-unstack > .column, .row.large-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 64em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 75em) {
  .xlarge-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .xlarge-offset-0 {
    margin-left: 0%;
  }
  .xlarge-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .xlarge-offset-1 {
    margin-left: 8.33333%;
  }
  .xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-offset-2 {
    margin-left: 16.66667%;
  }
  .xlarge-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .xlarge-offset-3 {
    margin-left: 25%;
  }
  .xlarge-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .xlarge-offset-4 {
    margin-left: 33.33333%;
  }
  .xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-offset-5 {
    margin-left: 41.66667%;
  }
  .xlarge-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .xlarge-offset-6 {
    margin-left: 50%;
  }
  .xlarge-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .xlarge-offset-7 {
    margin-left: 58.33333%;
  }
  .xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xlarge-offset-8 {
    margin-left: 66.66667%;
  }
  .xlarge-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .xlarge-offset-9 {
    margin-left: 75%;
  }
  .xlarge-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .xlarge-offset-10 {
    margin-left: 83.33333%;
  }
  .xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-offset-11 {
    margin-left: 91.66667%;
  }
  .xlarge-order-1 {
    order: 1;
  }
  .xlarge-order-2 {
    order: 2;
  }
  .xlarge-order-3 {
    order: 3;
  }
  .xlarge-order-4 {
    order: 4;
  }
  .xlarge-order-5 {
    order: 5;
  }
  .xlarge-order-6 {
    order: 6;
  }
  .xlarge-up-1 {
    flex-wrap: wrap;
  }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-up-2 {
    flex-wrap: wrap;
  }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-up-3 {
    flex-wrap: wrap;
  }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .xlarge-up-4 {
    flex-wrap: wrap;
  }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-up-5 {
    flex-wrap: wrap;
  }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xlarge-up-6 {
    flex-wrap: wrap;
  }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .xlarge-up-7 {
    flex-wrap: wrap;
  }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .xlarge-up-8 {
    flex-wrap: wrap;
  }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media screen and (min-width: 75em) and (min-width: 75em) {
  .xlarge-expand {
    flex: 1 1 0px;
  }
}

@media screen and (min-width: 75em) {
  .xlarge-flex-dir-row {
    flex-direction: row;
  }
  .xlarge-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .xlarge-flex-dir-column {
    flex-direction: column;
  }
  .xlarge-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
  .xlarge-flex-child-auto {
    flex: 1 1 auto;
  }
  .xlarge-flex-child-grow {
    flex: 1 0 auto;
  }
  .xlarge-flex-child-shrink {
    flex: 0 1 auto;
  }
}

.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
  flex: 0 0 100%;
}

@media screen and (min-width: 75em) {
  .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media screen and (min-width: 75em) {
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 100em) {
  .xxlarge-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .xxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxlarge-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .xxlarge-offset-1 {
    margin-left: 8.33333%;
  }
  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-offset-2 {
    margin-left: 16.66667%;
  }
  .xxlarge-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .xxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxlarge-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .xxlarge-offset-4 {
    margin-left: 33.33333%;
  }
  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-offset-5 {
    margin-left: 41.66667%;
  }
  .xxlarge-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .xxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxlarge-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .xxlarge-offset-7 {
    margin-left: 58.33333%;
  }
  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxlarge-offset-8 {
    margin-left: 66.66667%;
  }
  .xxlarge-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .xxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxlarge-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .xxlarge-offset-10 {
    margin-left: 83.33333%;
  }
  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-offset-11 {
    margin-left: 91.66667%;
  }
  .xxlarge-order-1 {
    order: 1;
  }
  .xxlarge-order-2 {
    order: 2;
  }
  .xxlarge-order-3 {
    order: 3;
  }
  .xxlarge-order-4 {
    order: 4;
  }
  .xxlarge-order-5 {
    order: 5;
  }
  .xxlarge-order-6 {
    order: 6;
  }
  .xxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .xxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .xxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .xxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media screen and (min-width: 100em) and (min-width: 100em) {
  .xxlarge-expand {
    flex: 1 1 0px;
  }
}

@media screen and (min-width: 100em) {
  .xxlarge-flex-dir-row {
    flex-direction: row;
  }
  .xxlarge-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .xxlarge-flex-dir-column {
    flex-direction: column;
  }
  .xxlarge-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
  .xxlarge-flex-child-auto {
    flex: 1 1 auto;
  }
  .xxlarge-flex-child-grow {
    flex: 1 0 auto;
  }
  .xxlarge-flex-child-shrink {
    flex: 0 1 auto;
  }
}

.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
  flex: 0 0 100%;
}

@media screen and (min-width: 100em) {
  .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media screen and (min-width: 100em) {
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

.column-block {
  margin-bottom: 1.25rem;
}

.column-block > :last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 40em) {
  .column-block {
    margin-bottom: 1.875rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: 700;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  line-height: 0;
  color: #cacaca;
}

h1 {
  font-size: 1.75rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h3 {
  font-size: 0.875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h5 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.375rem;
  }
  h3 {
    font-size: 0.875rem;
  }
  h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 1rem;
  }
}

a {
  line-height: inherit;
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}

a:hover, a:focus {
  color: black;
}

a img {
  border: 0;
}

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0;
}

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

dl {
  margin-bottom: 1rem;
}

dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold;
}

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca;
}

blockquote, blockquote p {
  line-height: 1.6;
  color: #1a1a1a;
}

cite {
  display: block;
  font-size: 0.8125rem;
  color: #1a1a1a;
}

cite:before {
  content: "— ";
}

abbr {
  border-bottom: 1px dotted #000000;
  color: #000000;
  cursor: help;
}

figure {
  margin: 0;
}

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e4e4e4;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #000000;
}

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e4e4e4;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #000000;
}

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #1a1a1a;
}

.lead {
  font-size: 125%;
  line-height: 1.6;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}

p + .stat {
  margin-top: -1rem;
}

.no-bullet {
  margin-left: 0;
  list-style: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media print, screen and (min-width: 30em) {
  .xsmall-text-left {
    text-align: left;
  }
  .xsmall-text-right {
    text-align: right;
  }
  .xsmall-text-center {
    text-align: center;
  }
  .xsmall-text-justify {
    text-align: justify;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left;
  }
  .medium-text-right {
    text-align: right;
  }
  .medium-text-center {
    text-align: center;
  }
  .medium-text-justify {
    text-align: justify;
  }
}

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left;
  }
  .large-text-right {
    text-align: right;
  }
  .large-text-center {
    text-align: center;
  }
  .large-text-justify {
    text-align: justify;
  }
}

@media screen and (min-width: 75em) {
  .xlarge-text-left {
    text-align: left;
  }
  .xlarge-text-right {
    text-align: right;
  }
  .xlarge-text-center {
    text-align: center;
  }
  .xlarge-text-justify {
    text-align: justify;
  }
}

@media screen and (min-width: 100em) {
  .xxlarge-text-left {
    text-align: left;
  }
  .xxlarge-text-right {
    text-align: right;
  }
  .xxlarge-text-center {
    text-align: center;
  }
  .xxlarge-text-justify {
    text-align: justify;
  }
}

.show-for-print {
  display: none !important;
}

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important;
  }
  .show-for-print {
    display: block !important;
  }
  .hide-for-print {
    display: none !important;
  }
  table.show-for-print {
    display: table !important;
  }
  thead.show-for-print {
    display: table-header-group !important;
  }
  tbody.show-for-print {
    display: table-row-group !important;
  }
  tr.show-for-print {
    display: table-row !important;
  }
  td.show-for-print {
    display: table-cell !important;
  }
  th.show-for-print {
    display: table-cell !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  pre,
  blockquote {
    border: 1px solid #1a1a1a;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  color: #000000;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none;
}

[type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
textarea:focus {
  outline: none;
  border: 1px solid #1a1a1a;
  background-color: #fefefe;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

textarea {
  max-width: 100%;
}

textarea[rows] {
  height: auto;
}

input::placeholder,
textarea::placeholder {
  color: #cacaca;
}

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e4e4e4;
  cursor: not-allowed;
}

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0;
}

input[type='search'] {
  box-sizing: border-box;
}

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem;
}

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
}

[type='checkbox'] + label[for],
[type='radio'] + label[for] {
  cursor: pointer;
}

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem;
}

[type='file'] {
  width: 100%;
}

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #000000;
}

label.middle {
  margin: 0 0 1rem;
  padding: 0.5625rem 0;
}

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #000000;
}

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch;
}

.input-group > :first-child {
  border-radius: 0 0 0 0;
}

.input-group > :last-child > * {
  border-radius: 0 0 0 0;
}

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
}

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e4e4e4;
  color: #000000;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}

.input-group-label:first-child {
  border-right: 0;
}

.input-group-label:last-child {
  border-left: 0;
}

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0;
}

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  flex: 0 0 auto;
}

.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  height: 2.5rem;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  max-width: 100%;
  margin-bottom: 0.5rem;
}

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca;
}

.fieldset legend {
  margin: 0;
  margin-left: -0.1875rem;
  padding: 0 0.1875rem;
  background: #e9e7e8;
}

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  color: #000000;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2826, 26, 26%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

@media screen and (min-width: 0\0) {
  select {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg==");
  }
}

select:focus {
  outline: none;
  border: 1px solid #1a1a1a;
  background-color: #fefefe;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

select:disabled {
  background-color: #e4e4e4;
  cursor: not-allowed;
}

select::-ms-expand {
  display: none;
}

select[multiple] {
  height: auto;
  background-image: none;
}

.is-invalid-input:not(:focus) {
  border-color: #ec5840;
  background-color: #fcedeb;
}

.is-invalid-input:not(:focus)::placeholder {
  color: #ec5840;
}

.is-invalid-label {
  color: #ec5840;
}

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #ec5840;
}

.form-error.is-visible {
  display: block;
}

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #000000;
  color: #fefefe;
}

[data-whatinput='mouse'] .button {
  outline: 0;
}

.button:hover, .button:focus {
  background-color: black;
  color: #fefefe;
}

.button.tiny {
  font-size: 0.6rem;
}

.button.small {
  font-size: 0.75rem;
}

.button.large {
  font-size: 1.25rem;
}

.button.expanded {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.button.primary {
  background-color: #000000;
  color: #fefefe;
}

.button.primary:hover, .button.primary:focus {
  background-color: black;
  color: #fefefe;
}

.button.secondary {
  background-color: #1a1a1a;
  color: #fefefe;
}

.button.secondary:hover, .button.secondary:focus {
  background-color: #151515;
  color: #fefefe;
}

.button.success {
  background-color: #3adb76;
  color: #000000;
}

.button.success:hover, .button.success:focus {
  background-color: #22bb5b;
  color: #000000;
}

.button.warning {
  background-color: #ffae00;
  color: #000000;
}

.button.warning:hover, .button.warning:focus {
  background-color: #cc8b00;
  color: #000000;
}

.button.alert {
  background-color: #ec5840;
  color: #000000;
}

.button.alert:hover, .button.alert:focus {
  background-color: #da3116;
  color: #000000;
}

.button.hollow {
  border: 1px solid #000000;
  color: #000000;
}

.button.hollow, .button.hollow:hover, .button.hollow:focus {
  background-color: transparent;
}

.button.hollow:hover, .button.hollow:focus {
  border-color: black;
  color: black;
}

.button.hollow.primary {
  border: 1px solid #000000;
  color: #000000;
}

.button.hollow.primary:hover, .button.hollow.primary:focus {
  border-color: black;
  color: black;
}

.button.hollow.secondary {
  border: 1px solid #1a1a1a;
  color: #1a1a1a;
}

.button.hollow.secondary:hover, .button.hollow.secondary:focus {
  border-color: #0d0d0d;
  color: #0d0d0d;
}

.button.hollow.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.hollow.success:hover, .button.hollow.success:focus {
  border-color: #157539;
  color: #157539;
}

.button.hollow.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.hollow.warning:hover, .button.hollow.warning:focus {
  border-color: #805700;
  color: #805700;
}

.button.hollow.alert {
  border: 1px solid #ec5840;
  color: #ec5840;
}

.button.hollow.alert:hover, .button.hollow.alert:focus {
  border-color: #881f0e;
  color: #881f0e;
}

.button.disabled, .button[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
  background-color: #000000;
  color: #fefefe;
}

.button.disabled.primary, .button[disabled].primary {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
  background-color: #000000;
  color: #fefefe;
}

.button.disabled.secondary, .button[disabled].secondary {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  background-color: #1a1a1a;
  color: #fefefe;
}

.button.disabled.success, .button[disabled].success {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
  background-color: #3adb76;
  color: #000000;
}

.button.disabled.warning, .button[disabled].warning {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
  background-color: #ffae00;
  color: #000000;
}

.button.disabled.alert, .button[disabled].alert {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
  background-color: #ec5840;
  color: #000000;
}

.button.dropdown::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4em;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #fefefe transparent transparent;
  position: relative;
  top: 0.4em;
  display: inline-block;
  float: right;
  margin-left: 1em;
}

.button.arrow-only::after {
  top: -0.1em;
  float: none;
  margin-left: 0;
}

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0;
  background-color: white;
  color: #000000;
}

.callout > :first-child {
  margin-top: 0;
}

.callout > :last-child {
  margin-bottom: 0;
}

.callout.primary {
  background-color: #d9d9d9;
  color: #000000;
}

.callout.secondary {
  background-color: #dddddd;
  color: #000000;
}

.callout.success {
  background-color: #e1faea;
  color: #000000;
}

.callout.warning {
  background-color: #fff3d9;
  color: #000000;
}

.callout.alert {
  background-color: #fce6e2;
  color: #000000;
}

.callout.small {
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.callout.large {
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  padding-left: 3rem;
}

.close-button {
  position: absolute;
  color: #1a1a1a;
  cursor: pointer;
}

[data-whatinput='mouse'] .close-button {
  outline: 0;
}

.close-button:hover, .close-button:focus {
  color: #000000;
}

.close-button.small {
  right: 0.66rem;
  top: 0.33em;
  font-size: 1.5em;
  line-height: 1;
}

.close-button, .close-button.medium {
  right: 1rem;
  top: 0.5rem;
  font-size: 2em;
  line-height: 1;
}

.menu {
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}

.menu > li {
  flex: 0 0 auto;
}

[data-whatinput='mouse'] .menu > li {
  outline: 0;
}

.menu > li > a {
  display: block;
  padding: 0.7rem 1rem;
  line-height: 1;
}

.menu input,
.menu select,
.menu a,
.menu button {
  margin-bottom: 0;
}

.menu > li > a {
  display: flex;
}

.menu > li > a {
  flex-flow: row nowrap;
}

.menu > li > a img,
.menu > li > a i,
.menu > li > a svg {
  margin-right: 0.25rem;
}

.menu, .menu.horizontal {
  flex-wrap: nowrap;
}

.menu > li, .menu.horizontal > li {
  flex: 0 0 auto;
}

.menu.expanded > li {
  flex: 1 1 0px;
}

.menu.expanded > li:first-child:last-child {
  width: 100%;
}

.menu.vertical {
  flex-wrap: wrap;
}

.menu.vertical > li {
  flex: 0 0 100%;
  max-width: 100%;
}

.menu.vertical > li > a {
  justify-content: flex-start;
  align-items: flex-start;
}

@media print, screen and (min-width: 30em) {
  .menu.xsmall-horizontal {
    flex-wrap: nowrap;
  }
  .menu.xsmall-horizontal > li {
    flex: 0 0 auto;
  }
  .menu.xsmall-expanded > li {
    flex: 1 1 0px;
  }
  .menu.xsmall-expanded > li:first-child:last-child {
    width: 100%;
  }
  .menu.xsmall-vertical {
    flex-wrap: wrap;
  }
  .menu.xsmall-vertical > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu.xsmall-vertical > li > a {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media print, screen and (min-width: 40em) {
  .menu.medium-horizontal {
    flex-wrap: nowrap;
  }
  .menu.medium-horizontal > li {
    flex: 0 0 auto;
  }
  .menu.medium-expanded > li {
    flex: 1 1 0px;
  }
  .menu.medium-expanded > li:first-child:last-child {
    width: 100%;
  }
  .menu.medium-vertical {
    flex-wrap: wrap;
  }
  .menu.medium-vertical > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu.medium-vertical > li > a {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media print, screen and (min-width: 64em) {
  .menu.large-horizontal {
    flex-wrap: nowrap;
  }
  .menu.large-horizontal > li {
    flex: 0 0 auto;
  }
  .menu.large-expanded > li {
    flex: 1 1 0px;
  }
  .menu.large-expanded > li:first-child:last-child {
    width: 100%;
  }
  .menu.large-vertical {
    flex-wrap: wrap;
  }
  .menu.large-vertical > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu.large-vertical > li > a {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media screen and (min-width: 75em) {
  .menu.xlarge-horizontal {
    flex-wrap: nowrap;
  }
  .menu.xlarge-horizontal > li {
    flex: 0 0 auto;
  }
  .menu.xlarge-expanded > li {
    flex: 1 1 0px;
  }
  .menu.xlarge-expanded > li:first-child:last-child {
    width: 100%;
  }
  .menu.xlarge-vertical {
    flex-wrap: wrap;
  }
  .menu.xlarge-vertical > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu.xlarge-vertical > li > a {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media screen and (min-width: 100em) {
  .menu.xxlarge-horizontal {
    flex-wrap: nowrap;
  }
  .menu.xxlarge-horizontal > li {
    flex: 0 0 auto;
  }
  .menu.xxlarge-expanded > li {
    flex: 1 1 0px;
  }
  .menu.xxlarge-expanded > li:first-child:last-child {
    width: 100%;
  }
  .menu.xxlarge-vertical {
    flex-wrap: wrap;
  }
  .menu.xxlarge-vertical > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu.xxlarge-vertical > li > a {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.menu.simple li {
  display: inline-block;
  vertical-align: top;
  line-height: 1;
}

.menu.simple a {
  padding: 0;
}

.menu.simple li {
  margin-left: 0;
  margin-right: 1rem;
}

.menu.simple.align-right li {
  margin-right: 0;
  margin-left: 1rem;
}

.menu.align-right {
  justify-content: flex-end;
}

.menu.icon-top > li > a {
  flex-flow: column nowrap;
}

.menu.icon-top > li > a img,
.menu.icon-top > li > a i,
.menu.icon-top > li > a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}

.menu.icon-top.vertical a > span {
  margin: auto;
}

.menu.nested {
  margin-left: 1rem;
}

.menu .active > a {
  background: #000000;
  color: #fefefe;
}

.menu.menu-bordered li {
  border: 1px solid #e4e4e4;
}

.menu.menu-bordered li:not(:first-child) {
  border-top: 0;
}

.menu.menu-hover li:hover {
  background-color: #e4e4e4;
}

.menu-text {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit;
}

.menu-centered {
  text-align: center;
}

.menu-centered > .menu {
  display: inline-block;
  vertical-align: top;
}

.no-js [data-responsive-menu] ul {
  display: none;
}

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}

.menu-icon::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #fefefe;
  box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
  content: '';
}

.menu-icon:hover::after {
  background: #cacaca;
  box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca;
}

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}

.menu-icon.dark::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #000000;
  box-shadow: 0 7px 0 #000000, 0 14px 0 #000000;
  content: '';
}

.menu-icon.dark:hover::after {
  background: #1a1a1a;
  box-shadow: 0 7px 0 #1a1a1a, 0 14px 0 #1a1a1a;
}

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto;
}

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #000000 transparent transparent;
  right: 5px;
  margin-top: -3px;
}

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0;
}

.no-js .dropdown.menu ul {
  display: none;
}

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0;
}

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
}

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%;
}

.dropdown.menu.vertical > li > a::after {
  right: 14px;
}

.dropdown.menu.vertical > li.opens-left > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #000000 transparent transparent;
}

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #000000;
}

@media print, screen and (min-width: 30em) {
  .dropdown.menu.xsmall-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.xsmall-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.xsmall-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.xsmall-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #000000 transparent transparent;
    right: 5px;
    margin-top: -3px;
  }
  .dropdown.menu.xsmall-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.xsmall-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
  }
  .dropdown.menu.xsmall-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.xsmall-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.xsmall-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #000000 transparent transparent;
  }
  .dropdown.menu.xsmall-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #000000;
  }
}

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #000000 transparent transparent;
    right: 5px;
    margin-top: -3px;
  }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
  }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #000000 transparent transparent;
  }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #000000;
  }
}

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #000000 transparent transparent;
    right: 5px;
    margin-top: -3px;
  }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
  }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #000000 transparent transparent;
  }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #000000;
  }
}

@media screen and (min-width: 75em) {
  .dropdown.menu.xlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.xlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.xlarge-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.xlarge-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #000000 transparent transparent;
    right: 5px;
    margin-top: -3px;
  }
  .dropdown.menu.xlarge-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.xlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
  }
  .dropdown.menu.xlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.xlarge-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.xlarge-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #000000 transparent transparent;
  }
  .dropdown.menu.xlarge-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #000000;
  }
}

@media screen and (min-width: 100em) {
  .dropdown.menu.xxlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.xxlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.xxlarge-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.xxlarge-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #000000 transparent transparent;
    right: 5px;
    margin-top: -3px;
  }
  .dropdown.menu.xxlarge-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.xxlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
  }
  .dropdown.menu.xxlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.xxlarge-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.xxlarge-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #000000 transparent transparent;
  }
  .dropdown.menu.xxlarge-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #000000;
  }
}

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto;
}

.is-dropdown-menu.vertical {
  width: 100px;
}

.is-dropdown-menu.vertical.align-right {
  float: right;
}

.is-dropdown-submenu-parent {
  position: relative;
}

.is-dropdown-submenu-parent a::after {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -6px;
}

.is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
  top: 100%;
  left: auto;
}

.is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
}

.is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%;
}

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe;
}

.is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
  right: 14px;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #000000 transparent transparent;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #000000;
}

.is-dropdown-submenu .is-dropdown-submenu {
  margin-top: -1px;
}

.is-dropdown-submenu > li {
  width: 100%;
}

.is-dropdown-submenu.js-dropdown-active {
  display: block;
}

.orbit {
  position: relative;
}

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
}

.orbit-slide {
  width: 100%;
}

.orbit-slide.no-motionui.is-active {
  top: 0;
  left: 0;
}

.orbit-figure {
  margin: 0;
}

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fefefe;
}

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fefefe;
}

[data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
  outline: 0;
}

.orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
  background-color: rgba(0, 0, 0, 0.5);
}

.orbit-previous {
  left: 0;
}

.orbit-next {
  left: auto;
  right: 0;
}

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center;
}

[data-whatinput='mouse'] .orbit-bullets {
  outline: 0;
}

.orbit-bullets button {
  width: 0.75rem;
  height: 0.75rem;
  margin: 0.1rem;
  border-radius: 50%;
  background-color: #cacaca;
}

.orbit-bullets button:hover {
  background-color: #1a1a1a;
}

.orbit-bullets button.is-active {
  background-color: #1a1a1a;
}

body.is-reveal-open {
  overflow: hidden;
}

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  user-select: none;
}

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(0, 0, 0, 0.45);
  overflow-y: scroll;
}

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto;
}

[data-whatinput='mouse'] .reveal {
  outline: 0;
}

@media print, screen and (min-width: 40em) {
  .reveal {
    min-height: 0;
  }
}

.reveal .column, .reveal .columns,
.reveal .columns {
  min-width: 0;
}

.reveal > :last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 40em) {
  .reveal {
    width: 600px;
    max-width: 75rem;
  }
}

@media print, screen and (min-width: 40em) {
  .reveal .reveal {
    right: auto;
    left: auto;
    margin: 0 auto;
  }
}

.reveal.collapse {
  padding: 0;
}

@media print, screen and (min-width: 40em) {
  .reveal.tiny {
    width: 30%;
    max-width: 75rem;
  }
}

@media print, screen and (min-width: 40em) {
  .reveal.small {
    width: 50%;
    max-width: 75rem;
  }
}

@media print, screen and (min-width: 40em) {
  .reveal.large {
    width: 90%;
    max-width: 75rem;
  }
}

.reveal.full {
  top: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: 100%;
  height: 100vh;
  min-height: 100vh;
  margin-left: 0;
  border: 0;
  border-radius: 0;
}

@media screen and (max-width: 29.9375em) {
  .reveal {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0;
  }
}

.reveal.without-overlay {
  position: fixed;
}

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0);
}

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0);
}

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0);
}

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0);
}

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%);
}

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%);
}

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%);
}

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%);
}

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity;
}

.fade-in.mui-enter.mui-enter-active {
  opacity: 1;
}

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity;
}

.fade-out.mui-leave.mui-leave-active {
  opacity: 0;
}

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0;
}

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0;
}

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0;
}

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0;
}

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0;
}

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0;
}

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0;
}

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1;
}

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0;
}

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1;
}

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1;
}

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0;
}

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1;
}

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0;
}

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0;
}

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1;
}

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1;
}

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0;
}

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0;
}

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1;
}

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1;
}

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0;
}

.slow {
  transition-duration: 750ms !important;
}

.fast {
  transition-duration: 250ms !important;
}

.linear {
  transition-timing-function: linear !important;
}

.ease {
  transition-timing-function: ease !important;
}

.ease-in {
  transition-timing-function: ease-in !important;
}

.ease-out {
  transition-timing-function: ease-out !important;
}

.ease-in-out {
  transition-timing-function: ease-in-out !important;
}

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
}

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
}

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
}

.short-delay {
  transition-delay: 300ms !important;
}

.long-delay {
  transition-delay: 700ms !important;
}

.shake {
  animation-name: shake-7;
}

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%);
  }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%);
  }
}

.spin-cw {
  animation-name: spin-cw-1turn;
}

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn);
  }
  100% {
    transform: rotate(0);
  }
}

.spin-ccw {
  animation-name: spin-cw-1turn;
}

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}

.wiggle {
  animation-name: wiggle-7deg;
}

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg);
  }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg);
  }
  0%, 30%, 70%, 100% {
    transform: rotate(0);
  }
}

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms;
}

.infinite {
  animation-iteration-count: infinite;
}

.slow {
  animation-duration: 750ms !important;
}

.fast {
  animation-duration: 250ms !important;
}

.linear {
  animation-timing-function: linear !important;
}

.ease {
  animation-timing-function: ease !important;
}

.ease-in {
  animation-timing-function: ease-in !important;
}

.ease-out {
  animation-timing-function: ease-out !important;
}

.ease-in-out {
  animation-timing-function: ease-in-out !important;
}

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
}

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
}

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
}

.short-delay {
  animation-delay: 300ms !important;
}

.long-delay {
  animation-delay: 700ms !important;
}

/* ===================================================================================================================
   Page Layout
   ================================================================================================================ */
html {
  height: 100%;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

body {
  height: 100%;
  font-size: 0.875rem;
}

body.overflow-hidden {
  overflow: hidden;
}

.page_wrapper {
  height: 100%;
}

.page_column-wrapper {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.page_layout-0-1-0 .page_column-1 {
  width: 100%;
  height: 100%;
}

@media print, screen and (min-width: 64em) {
  .page_layout-3-1-0 .page_column-1 {
    float: right;
    width: 75%;
  }
  .page_layout-3-1-0 .page_column-3 {
    margin: 0 75% 0 0;
  }
  .page_layout-3-1-0 .page_content {
    margin-left: 1.25rem;
  }
  .page_layout-3-1-0 .page_sidebar {
    margin-right: 1.25rem;
  }
}

@media print, screen and (min-width: 64em) {
  .page_layout-1-3-0 .page_column-1 {
    float: left;
    width: 25%;
  }
  .page_layout-1-3-0 .page_column-3 {
    margin: 0 0 0 25%;
  }
  .page_layout-1-3-0 .page_content {
    margin-left: 1.25rem;
  }
  .page_layout-1-3-0 .page_sidebar {
    margin-right: 1.25rem;
  }
}

/* ===================================================================================================================
   Grid - Foundation
   ================================================================================================================ */
.expanded .row {
  margin-left: auto;
  margin-right: auto;
  max-width: 75rem;
}

.bg-color-lightgrey {
  border-left: 0.69444rem solid #e4e4e4;
  border-right: 0.69444rem solid #e4e4e4;
  padding: 1.5rem;
  padding-top: 2rem;
  background-color: #e4e4e4;
}

@media print, screen and (min-width: 40em) {
  .bg-color-lightgrey {
    border-left: 1.04167rem solid #e4e4e4;
    border-right: 1.04167rem solid #e4e4e4;
  }
}

.bg-color-primary {
  border-left: 0.69444rem solid #e4e4e4;
  border-right: 0.69444rem solid #e4e4e4;
  padding: 1.5rem;
  padding-top: 2rem;
  background-color: #000000;
}

@media print, screen and (min-width: 40em) {
  .bg-color-primary {
    border-left: 1.04167rem solid #e4e4e4;
    border-right: 1.04167rem solid #e4e4e4;
  }
}

.row-one-column > * {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .row-one-column > * {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.row-two-columns > * {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .row-two-columns > * {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row-two-columns > * {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 50%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .row-two-columns > * {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.row-three-columns > * {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .row-three-columns > * {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 40em) {
  .row-three-columns > * {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 50%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .row-three-columns > * {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row-three-columns > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 33.33333%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .row-three-columns > * {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.row-four-columns > * {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .row-four-columns > * {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 40em) {
  .row-four-columns > * {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 50%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .row-four-columns > * {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row-four-columns > * {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 25%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .row-four-columns > * {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.row-big-small > *:nth-child(odd) {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .row-big-small > *:nth-child(odd) {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 40em) {
  .row-big-small > *:nth-child(odd) {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 50%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .row-big-small > *:nth-child(odd) {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row-big-small > *:nth-child(odd) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 66.66667%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .row-big-small > *:nth-child(odd) {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.row-big-small > *:nth-child(even) {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .row-big-small > *:nth-child(even) {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 40em) {
  .row-big-small > *:nth-child(even) {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 50%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .row-big-small > *:nth-child(even) {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row-big-small > *:nth-child(even) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 33.33333%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .row-big-small > *:nth-child(even) {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.row-small-big > *:nth-child(odd) {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .row-small-big > *:nth-child(odd) {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 40em) {
  .row-small-big > *:nth-child(odd) {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 50%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .row-small-big > *:nth-child(odd) {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row-small-big > *:nth-child(odd) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 33.33333%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .row-small-big > *:nth-child(odd) {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.row-small-big > *:nth-child(even) {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .row-small-big > *:nth-child(even) {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 40em) {
  .row-small-big > *:nth-child(even) {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 50%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .row-small-big > *:nth-child(even) {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row-small-big > *:nth-child(even) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 66.66667%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .row-small-big > *:nth-child(even) {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.remove-grid {
  width: 100% !important;
  flex: 0 0 100% !important;
  max-width: none !important;
}

/* ===================================================================================================================
   Miscellaneous - Global
   ================================================================================================================ */
.invisible {
  display: none !important;
  visibility: hidden !important;
}

.float_above {
  margin-bottom: 20px;
}

.float_left {
  margin-bottom: 20px;
}

@media print, screen and (min-width: 64em) {
  .float_left {
    float: left;
    margin-right: 20px;
  }
}

.float_right {
  margin-bottom: 20px;
}

@media print, screen and (min-width: 64em) {
  .float_right {
    float: right;
    margin-left: 20px;
  }
}

figure {
  margin: 0;
}

button,
select,
.submit,
.reveal,
a.edit {
  outline: 0;
}

p.error,
.mod_iso_cart .coupon .failed {
  padding: 0.85em 0.5em;
  font-size: 0.75rem;
  font-weight: 700;
  color: #fefefe;
  background: #ec5840;
}

/* ===================================================================================================================
   Typography - Global
   ================================================================================================================ */
body {
  letter-spacing: 0.05em;
}

h1, h2, h3, h4, h5, h6,
.ce_item-media-teaser__subheadline {
  letter-spacing: 0.05em;
  font-weight: 700;
}

h1 {
  padding-top: 0.5rem;
  font-weight: 400;
  text-transform: uppercase;
  padding-bottom: 1rem;
}

h3 {
  font-weight: 400;
  text-transform: uppercase;
}

p, td, li {
  font-size: 0.875rem;
}

.reveal ol,
.page_content ol {
  margin-left: 1.75rem;
}

select {
  border: 1px solid #000000;
  margin: 0 0 0.5rem;
}

.page_column-1 select {
  border: 1px solid #cacaca;
  margin: 0 0 1rem;
}

#ctrl_target {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

option {
  text-transform: none !important;
  letter-spacing: 0;
}

/* ===================================================================================================================
   Page Main Elements - Header, Teaser, Content, Sidebar, Footer
   ================================================================================================================ */
.top-navigation {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #e9e7e8;
}

@media print, screen and (min-width: 64em) {
  .top-navigation {
    padding-top: 1rem;
  }
}

.top-navigation_logo {
  margin-top: 1.25em;
  z-index: 100;
}

.top-navigation_logo .image_container {
  margin-bottom: 0.5em;
}

.top-navigation_shop {
  position: relative;
  margin-top: -20px;
  margin-bottom: 10px;
  text-align: right;
}

@media print, screen and (min-width: 64em) {
  .top-navigation_shop {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.top-navigation_shop .link_basket:after {
  content: "";
  font-size: 14px;
}

.page_teaser {
  position: relative;
  margin-top: 7em;
}

@media print, screen and (min-width: 64em) {
  .page_teaser {
    margin-top: -1.5em;
  }
}

.page_teaser .mod_navigation ul {
  list-style: none;
  margin-left: 0;
}

.page_teaser .mod_navigation li {
  display: inline;
  text-transform: uppercase;
  padding-left: 0.5rem;
}

.page_teaser .mod_navigation li:first-child {
  padding-left: 0;
}

.page_wrapper {
  position: relative;
  top: 0;
}

@media print, screen and (min-width: 64em) {
  .page_wrapper {
    top: 12em;
  }
}

.page_column-wrapper {
  max-width: 75rem;
  margin: 0 auto;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

@media print, screen and (min-width: 40em) {
  .page_column-wrapper {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}

@media print, screen and (min-width: 40em) {
  .page_column-wrapper {
    margin-top: 45px;
  }
}

.page_type-home .page_column-wrapper,
.page_type-shoplist .page_column-wrapper {
  max-width: none;
  padding-left: 0;
  padding-right: 0;
}

@media print, screen and (min-width: 40em) {
  .page_type-home .page_column-wrapper,
  .page_type-shoplist .page_column-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

.page_content .ce_text a, .page_content .ce_item-intro-text__content a {
  text-decoration: underline;
}

.page_content .ce_text a.button {
  text-decoration: none;
}

.image_container {
  position: relative;
  margin-bottom: 2em;
}

.ce_image .caption {
  text-transform: uppercase;
  position: relative;
  background-color: black;
  bottom: 0;
  color: #fefefe;
  width: 100%;
  padding: 1rem;
  z-index: 2;
}

@media print, screen and (min-width: 40em) {
  .ce_image .caption {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.page_content .ce_image a {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
}

.page_content .image_container {
  display: block;
  position: relative;
}

.page_content .image_container a {
  position: relative;
  height: 100%;
  width: 100%;
  display: block;
}

.page_content .image_container a:before {
  content: '';
  position: absolute;
  background-color: rgba(26, 26, 26, 0.7);
  background-image: url("../img/signet-atelier-cincin-white.svg");
  background-position: center center;
  background-size: 40px 40px;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  transition: 0.2s opacity linear;
}

.page_content .image_container a:hover:before {
  opacity: 1;
}

.page_sidebar .ce_image {
  margin-bottom: 1rem;
}

.page_sidebar-quicknavigation {
  margin-bottom: 1rem;
}

.page_sidebar-quicknavigation select {
  margin-bottom: 0;
}

.page_sidebar-quicknavigation,
.page_sidebar-basket-mini {
  margin-bottom: 1rem;
}

@media screen and (max-width: 63.9375em) {
  .page_sidebar-quicknavigation,
  .page_sidebar-basket-mini {
    display: none !important;
  }
}

.page_sidebar-isotope-filter,
.page_sidebar-basket-mini {
  padding: 0.625rem;
  background: #dddadb;
}

.page_sidebar-isotope-filter {
  margin-bottom: 1rem;
}

.page_sidebar-backlink {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media print, screen and (min-width: 64em) {
  .page_sidebar-backlink {
    margin-bottom: 0;
  }
}

.page_sidebar-backlink .backlink {
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.page_sidebar-backlink .backlink:before {
  content: "";
  margin-right: 10px;
  font-size: 18px;
  top: 3px;
  position: relative;
  margin-right: 0;
}

.page_sidebar-subnavigation {
  margin-top: 2rem;
}

@media print, screen and (min-width: 64em) {
  .page_sidebar-subnavigation {
    margin-top: 0;
  }
}

.page_sidebar-subnavigation ul {
  margin-left: 0;
  list-style-type: none;
}

.page_sidebar-subnavigation a {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.mod_breadcrumb,
.mod_breadcrumb a {
  text-transform: uppercase;
}

.page_footer {
  background: #d7d7d7;
  color: #000000;
  margin-top: 30px;
  padding-top: 0.1rem;
}

.page_footer a {
  color: #000000;
}

.page_footer a:focus,
.page_footer a:hover {
  text-decoration: underline;
}

.page_footer a.button:focus,
.page_footer a.button:hover {
  text-decoration: none;
}

.page_footer .row:first-child {
  margin-top: 30px;
}

.page_footer .ce_text {
  margin-bottom: 20px;
}

.page_footer .ce_text:nth-child(3) img {
  max-width: 50px;
}

.page_footer h2 {
  text-transform: uppercase;
  color: white;
  padding-bottom: 1rem;
}

/* ===================================================================================================================
   Page Type Elements
   ================================================================================================================ */
/* ===================================================================================================================
   Content Type Elements
   ================================================================================================================ */
.content_type-social .social-links {
  float: right;
}

.content_type-social a {
  font-size: 1.25rem;
}

.content_type-social a:before {
  content: "";
  margin-right: 10px;
}

.content_type-social a:hover, .content_type-social a:focus, .content_type-social a:active {
  text-decoration: none;
}

.content_type-social a span {
  text-indent: -9999px;
  display: inline-block;
}

.content_type-social a.pinterest {
  font-size: 1.375rem;
}

.content_type-social a.pinterest:before {
  content: "";
  margin-right: 10px;
}

.content_type-social a.instagram:before {
  content: "";
  margin-right: 10px;
}

.content_type-copyright p {
  font-size: 0.75rem;
}

/* ===================================================================================================================
   Navigation - Foundation
   ================================================================================================================ */
@media screen and (max-width: 29.9375em) {
  .no-js .top-bar {
    display: none;
  }
}

@media print, screen and (min-width: 40em) {
  .no-js .title-bar {
    display: none;
  }
}

@media print, screen and (min-width: 64em) {
  .logo {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 33.33333%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .logo {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.title-bar {
  position: relative;
  z-index: 999;
  width: 100%;
  background-color: #000000;
  padding: 0.5rem;
}

.top-bar {
  background-color: transparent;
  position: relative;
  z-index: 999;
  padding: 0;
  width: 100%;
}

@media print, screen and (min-width: 64em) {
  .top-bar {
    padding-top: 20px;
    float: right;
  }
}

.title-bar .menu-icon {
  margin: 0 auto;
}

.menu-icon:after {
  box-shadow: 0 6px 0 #fefefe, 0 12px 0 #fefefe;
}

.menu-icon:hover:after {
  box-shadow: 0 6px 0 #fefefe, 0 12px 0 #fefefe;
}

.top-bar .top-bar-right {
  width: 100%;
}

@media print, screen and (min-width: 64em) {
  .top-bar .top-bar-right {
    position: relative;
    margin-right: 20px;
  }
}

.top-bar a {
  color: #000000;
  text-transform: uppercase;
  padding: 0.7rem 1rem;
}

@media print, screen and (min-width: 64em) {
  .top-bar a {
    font-size: 0.875rem;
  }
}

.top-bar a.active, .top-bar a.trail {
  background-color: transparent;
}

@media print, screen and (min-width: 64em) {
  .top-bar a.active, .top-bar a.trail {
    color: #000000;
  }
}

.top-bar .menu.nested {
  background: #000000;
  margin-left: 0;
}

@media print, screen and (min-width: 64em) {
  .top-bar .menu.nested {
    box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.2);
    background: #1a1a1a;
  }
}

@media print, screen and (min-width: 64em) {
  .top-bar ul {
    float: right;
  }
}

.top-bar ul ul a {
  color: #fefefe;
  text-transform: none;
  letter-spacing: 0.05em;
  padding: 0.7rem 1rem;
  border-bottom: 1px solid rgba(254, 254, 254, 0.05);
}

@media print, screen and (min-width: 64em) {
  .top-bar ul ul a {
    border-bottom: 0px solid rgba(254, 254, 254, 0.05);
  }
}

.top-bar ul ul a.active,
.top-bar ul ul a.trail {
  background-color: #010101;
  color: #fefefe;
}

@media print, screen and (min-width: 64em) {
  .top-bar ul ul a:hover,
  .top-bar ul ul a:focus {
    background-color: black;
  }
}

.top-bar ul.vertical.dropdown {
  background: transparent;
}

.is-dropdown-submenu {
  min-width: 270px;
  border: 0;
}

.dropdown.menu.vertical.dropdown > li.is-dropdown-submenu-parent > a:after,
.is-accordion-submenu-parent > a:after {
  border-color: #fefefe transparent transparent;
  border: 0 solid;
}

.dropdown.menu.vertical.dropdown > li.is-dropdown-submenu-parent > a:after,
.is-accordion-submenu-parent > a:after {
  content: "";
  color: #fefefe;
  font-size: .5rem;
  right: 10px;
  margin-top: 2px;
  position: absolute;
  width: 8px;
  height: 8px;
}

@media print, screen and (min-width: 64em) {
  .dropdown.menu.vertical.dropdown > li.is-dropdown-submenu-parent > a:after,
  .is-accordion-submenu-parent > a:after {
    color: #000000;
    top: 11px;
    right: 8px;
    width: 8px;
  }
}

.top-bar .accordion-menu {
  background-color: black;
  color: #fefefe;
}

.top-bar .accordion-menu li a {
  color: #fefefe;
}

.top-bar .accordion-menu .is-accordion-submenu-item a {
  padding-left: 25px;
}

/* ===================================================================================================================
   Isotope
   ================================================================================================================ */
[class*="mod_iso"] .clear {
  display: none !important;
  visibility: hidden !important;
}

[class*="mod_iso"] .review a.edit {
  float: right;
  overflow: hidden;
}

.mod_iso_productlist .image_container {
  text-align: center;
}

.mod_iso_productlist .empty.message {
  margin-top: 1rem;
}

@media print, screen and (min-width: 64em) {
  .mod_iso_productlist .empty.message {
    margin-top: 0;
  }
}

.product_list {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

.product_list .product {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 50%;
  margin-bottom: 37px;
}

@media print, screen and (min-width: 40em) {
  .product_list .product {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 40em) {
  .product_list .product {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 25%;
    margin-bottom: 47px;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .product_list .product {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.product_list.similar_products {
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 1px solid #cacaca;
}

.product_list.similar_products .product {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 50%;
  margin-bottom: 37px;
  margin-top: 2rem;
}

@media print, screen and (min-width: 40em) {
  .product_list.similar_products .product {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 40em) {
  .product_list.similar_products .product {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 16.66667%;
    margin-bottom: 47px;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .product_list.similar_products .product {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.product_list .formbody {
  position: relative;
}

.product_list .image_container {
  background: #fefefe;
}

.product_list .image_container img {
  width: 100%;
}

.product_list .price {
  z-index: 2;
}

.product_list .details {
  display: none !important;
  visibility: hidden !important;
}

.product_list .property_status-new {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
}

.product_list .property_status-new:before {
  z-index: 2;
  position: absolute;
  content: 'NEU';
  top: 0;
  right: 0.5em;
  font-family: "Lato", sans-serif !important;
  background-color: #000000;
  font-size: 1.125rem;
  padding: 4px;
  padding-top: 6px;
  color: #fefefe;
  line-height: 1;
}

.mod_iso_productfilter .column_filter {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .mod_iso_productfilter .column_filter {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 30em) {
  .mod_iso_productfilter .column_filter {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 50%;
  }
}

@media print, screen and (min-width: 30em) and (min-width: 40em) {
  .mod_iso_productfilter .column_filter {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 40em) {
  .mod_iso_productfilter .column_filter {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 25%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .mod_iso_productfilter .column_filter {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.mod_iso_productfilter .column_clear {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .mod_iso_productfilter .column_clear {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 46.875em) {
  .mod_iso_productfilter .column_clear {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 25%;
  }
}

@media screen and (min-width: 46.875em) and (min-width: 40em) {
  .mod_iso_productfilter .column_clear {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.mod_iso_productfilter .search {
  position: relative;
}

.mod_iso_productfilter .search label {
  display: none;
  visibility: hidden;
}

.mod_iso_productfilter .search .awesomplete {
  display: block;
  width: 100%;
}

.mod_iso_productfilter .search input[type="text"] {
  padding-right: 50px;
  padding-left: 15px;
}

.mod_iso_productfilter .search input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 2.4375rem;
  margin: 0;
  background: #000000 url("../img/icon-search.svg") no-repeat center;
  background-size: 20px 20px;
}

.mod_iso_productreader img {
  background: #535152;
}

.product_information .name,
.product_information .quantity_container label {
  display: none !important;
  visibility: hidden !important;
}

.mod_iso_productreader .formbody {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  max-width: none;
  margin-left: -10px;
  margin-right: -10px;
}

@media print, screen and (min-width: 40em) {
  .mod_iso_productreader .formbody {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.product_gallery,
.product_information {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .product_gallery,
  .product_information {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 30em) {
  .product_gallery,
  .product_information {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 50%;
  }
}

@media print, screen and (min-width: 30em) and (min-width: 40em) {
  .product_gallery,
  .product_information {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.product_gallery-thumbnails {
  margin-top: 1rem;
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  max-width: none;
  margin-left: -10px;
  margin-right: -10px;
}

@media print, screen and (min-width: 40em) {
  .product_gallery-thumbnails {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.product_gallery-thumbnails figure {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 33.33333%;
}

@media print, screen and (min-width: 40em) {
  .product_gallery-thumbnails figure {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.product_information .product_category {
  font-size: 1.25rem;
  margin-top: 1rem;
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: 400;
}

@media print, screen and (min-width: 30em) {
  .product_information .product_category {
    margin-top: 0;
  }
}

@media print, screen and (min-width: 40em) {
  .product_information .product_category {
    font-size: 1.375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .product_information .product_category {
    font-size: 1.625rem;
  }
}

.product_information .sku {
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.product_information .price {
  margin-bottom: 0;
  font-size: 1.125rem;
}

@media print, screen and (min-width: 40em) {
  .product_information .price {
    font-size: 1.5rem;
  }
}

.product_additional-information {
  font-size: 0.75rem;
  margin-bottom: 30px;
}

.mod_iso_productreader .quantity_container {
  float: left;
  width: 40px;
  margin-right: 10px;
}

.product_information .quantity_container input {
  text-align: center;
}

.mod_iso_productreader .submit {
  display: inline-block;
  margin-top: 0;
}

.mod_iso_productreader .product_properties {
  margin-top: 1rem;
}

.mod_iso_productreader .property_finish ul {
  display: inline;
  margin-left: 0;
  list-style-type: none;
}

.mod_iso_productreader .property_finish li {
  display: inline;
}

.mod_iso_productreader .product_information-ring-measure p {
  font-size: 0.75rem;
}

.mod_iso_productreader .product_information-ring-measure a {
  padding-bottom: 1px;
  border-bottom: 1px solid #232323;
}

.mod_iso_productreader .product_information-delivery {
  font-size: 0.875rem;
  margin-top: 1rem;
}

.mod_iso_productreader .property_status-new:before {
  z-index: 2;
  position: relative;
  content: 'NEU';
  top: -10px;
  right: 0;
  font-family: "Lato", sans-serif !important;
  background-color: #000000;
  font-size: 1.125rem;
  padding: 4px;
  color: #fefefe;
  line-height: 1;
}

.mod_iso_productreader .property_status-ordered {
  margin-top: 1rem;
}

.mod_iso_productreader .property_status-ordered span:before {
  content: "";
  margin-right: 10px;
  margin-right: 5px;
  font-size: 14px;
}

.page_content-basket-maxi table {
  width: 100%;
  table-layout: fixed;
}

.basket-maxi_content tr {
  border-bottom: 1px solid #000000;
}

.basket-maxi_content td.remove,
.basket-maxi_summary td.remove,
.basket-maxi_summary td.col_0,
.basket-maxi_summary td.col_3 {
  display: none !important;
  visibility: hidden !important;
}

.basket-maxi_content .image {
  display: none;
  visibility: hidden;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 15px;
}

@media print, screen and (min-width: 30em) {
  .basket-maxi_content .image {
    display: block;
    visibility: visible;
    width: 50px;
  }
}

@media print, screen and (min-width: 40em) {
  .basket-maxi_content .image {
    width: 100px;
  }
}

.basket-maxi_content .remove a {
  float: right;
}

.basket-maxi_content .remove a:before {
  content: "";
  margin-right: 10px;
}

.basket-maxi_content .name {
  width: 100%;
  font-size: 0.875rem !important;
  display: block;
  padding: 0.25em 0.5em 0.25em 0;
  font-weight: 700;
}

@media print, screen and (min-width: 30em) {
  .basket-maxi_content .name {
    display: table-cell;
    padding-left: 50px;
  }
}

@media print, screen and (min-width: 40em) {
  .basket-maxi_content .name {
    padding-left: 100px;
  }
}

.basket-maxi_content .name ul {
  margin-left: 0;
  list-style-type: none;
}

.basket-maxi_content .name ul strong {
  font-weight: 400;
}

.basket-maxi_content .name a,
.basket-maxi_content .name ul li p {
  font-size: 0.875rem !important;
}

.basket-maxi_content .quantity {
  display: inline-block;
  width: 20%;
  padding: 0.25em 0.5em 0.25em 0;
}

@media print, screen and (min-width: 30em) {
  .basket-maxi_content .quantity {
    display: table-cell;
    width: 2.625rem;
    padding-left: 0.5em;
  }
}

.basket-maxi_content .quantity input {
  width: 2.5rem;
  height: 1.375rem;
  margin: 0;
  padding: 0;
  text-align: center;
}

.basket-maxi_content .price,
.basket-maxi_content .price.total {
  display: inline-block;
  width: 40%;
  text-align: right;
  padding: 0.25em;
}

@media print, screen and (min-width: 30em) {
  .basket-maxi_content .price,
  .basket-maxi_content .price.total {
    display: table-cell;
    width: 7.5rem;
    padding: 0.25em 0.5em;
  }
}

.basket-maxi_content .price.total {
  padding-right: 0;
}

.basket-maxi_summary .name {
  text-align: right;
}

.basket-maxi_summary .price.total {
  width: 7.5rem;
  padding: 0.25em 0.5em;
  text-align: right;
}

.basket-maxi_summary .price.total {
  padding-right: 0;
}

.basket-maxi_summary .total .name,
.basket-maxi_summary .total .price {
  font-weight: 700;
}

.mod_iso_cart .coupon {
  margin-top: 1rem;
}

.mod_iso_cart .coupon input {
  max-width: 200px;
}

.page_content-basket-mini .coupon {
  display: none !important;
  visibility: hidden !important;
}

.page_content-basket-mini h2 {
  margin-bottom: 1rem;
  line-height: 1;
  font-size: 0.9375rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.page_content-basket-mini h2:before {
  content: "";
  margin-right: 10px;
  font-size: 16px;
}

.page_content-basket-mini .product {
  margin-bottom: 0.3125rem;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #000000;
}

.page_content-basket-mini .name {
  font-weight: 700;
}

.page_content-basket-mini .remove {
  float: right;
  overflow: hidden;
}

.page_content-basket-mini .remove a:after {
  content: "";
  margin-left: 10px;
}

.page_content-basket-mini .price {
  text-align: right;
}

.page_content-basket-mini .info ul {
  margin-left: 0;
  list-style-type: none;
}

.page_content-basket-mini .info ul strong {
  font-weight: 400;
}

.page_content-basket-mini .subtotal {
  text-align: right;
}

.page_content-basket-mini .coupon h2 {
  margin-bottom: 1rem;
  line-height: 1;
  font-size: 0.9375rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.page_content-basket-mini .coupon h2:before {
  content: "";
  margin-right: 10px;
  font-size: 16px;
}

.mod_iso_checkout .address_new {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  max-width: none;
  margin-left: -10px;
  margin-right: -10px;
}

@media print, screen and (min-width: 40em) {
  .mod_iso_checkout .address_new {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.mod_iso_checkout .address_new .widget {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 50%;
}

@media print, screen and (min-width: 40em) {
  .mod_iso_checkout .address_new .widget {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.mod_iso_checkout .address_new .widget:nth-child(1),
.mod_iso_checkout .address_new .widget:nth-child(10) {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .mod_iso_checkout .address_new .widget:nth-child(1),
  .mod_iso_checkout .address_new .widget:nth-child(10) {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.mod_iso_checkout .shippingaddress {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #000000;
}

.mod_iso_checkout .shippingaddress .widget-radio span {
  display: block;
}

.mod_iso_checkout .shippingmethod .widget-radio input[type="radio"] label {
  display: block;
}

.mod_iso_checkout .shippingmethod .widget-radio .radio_container > span {
  display: block;
}

.mod_iso_checkout .shippingmethod .widget-radio > * {
  display: inline;
}

.mod_iso_checkout .shippingmethod .widget-radio label {
  margin: 0;
}

.mod_iso_checkout .paymentmethod .radio_container span {
  display: block;
}

.mod_iso_checkout .orderinfo .info_container:first-of-type,
.mod_iso_orderdetails .info_container:first-of-type {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #000000;
}

.mod_iso_checkout .orderinfo .info_container,
.mod_iso_orderdetails .info_container {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #000000;
}

.mod_iso_checkout .orderinfo .info_container.last,
.mod_iso_orderdetails .info_container.last {
  margin-bottom: 0;
}

.mod_iso_checkout .orderinfo .info_container.shipping_method a.edit {
  display: none;
  visibility: hidden;
}

.mod_iso_checkout .orderconditionsafterproducts {
  padding-top: 1rem;
  border-top: 1px solid #000000;
}

.mod_iso_orderdetails h2 {
  font-size: 1.125rem;
}

.mod_iso_orderhistory table {
  width: 100%;
  text-align: left;
}

.mod_iso_orderhistory tr {
  border-bottom: 1px solid #000000;
}

.mod_iso_orderhistory td {
  padding: 10px 0;
}

.mod_iso_orderhistory th:nth-child(2),
.mod_iso_orderhistory td:nth-child(2) {
  padding-left: 5px;
  padding-right: 5px;
}

.mod_iso_orderhistory .oder_status {
  text-align: right;
}

#iso_ajaxBox {
  width: 80%;
  padding: 20px;
  position: absolute;
  top: 20px;
  left: 10%;
  border: 1px solid #000000;
  /*	font-family:"Trebuchet MS",Verdana,sans-serif;
		font-size:15px;
		line-height: 18px;*/
  text-align: center;
  color: #000000;
  z-index: 9999;
}

#iso_ajaxBox.btnClose {
  background-image: none;
  cursor: pointer;
}

#iso_ajaxBox p {
  margin: 1em 0;
  padding: 0;
}

#iso_ajaxBox .iso_error {
  padding-left: 0;
  background: transparent;
  /*	padding-left: 34px;
		background: url("../images/cross-24.png") left center no-repeat;*/
}

#iso_ajaxBox .iso_confirm {
  padding-left: 0;
  background: transparent;
  /*	padding-left: 34px;
		background: url("../images/tick-24.png") left center no-repeat;*/
}

#iso_ajaxBox .iso_info {
  padding-left: 0;
  background: transparent;
  /*	padding-left: 34px;
		background: url("../images/information-24.png") left center no-repeat;*/
}

#iso_ajaxOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(34, 34, 34, 0.85);
  z-index: 9998;
  /*	background-color : #000000;
		-moz-opacity     : 0.5;
		opacity          : 0.5;
		_display         : none;
		filter           : Alpha(opacity=50);*/
}

/* ===================================================================================================================
   Pagination
   ================================================================================================================ */
.pagination {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

.pagination ul {
  text-align: right;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
  margin-left: 0;
}

@media print, screen and (min-width: 40em) {
  .pagination ul {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.pagination p,
.pagination ul li.next,
.pagination ul li.last,
.pagination ul li.first,
.pagination ul li.previous {
  display: none !important;
  visibility: hidden;
}

.pagination ul {
  display: inline-block;
}

.pagination li {
  display: inline-block;
  margin-left: 5px;
}

.pagination li:first-child {
  margin-left: 0;
}

.pagination li a,
.pagination li span {
  border: 1px solid #000000;
  padding: 5px 10px;
}

.pagination li a {
  text-decoration: none;
}

.pagination li span {
  color: #fefefe;
  background: #000000;
}

.pagination li a:hover,
.pagination li a:focus {
  color: #fefefe;
  background: #1a1a1a;
}

/* ===================================================================================================================
   Breadcrumbs Multi Step Indicator
   ================================================================================================================ */
.steps ol {
  margin-left: 0;
  text-transform: uppercase;
}

.steps .text-center {
  text-align: left;
}

/* --------------------------------
Basic Style
-------------------------------- */
.cd-breadcrumb,
.cd-multi-steps {
  width: 100%;
}

.cd-breadcrumb:after,
.cd-multi-steps:after {
  content: "";
  display: table;
  clear: both;
}

.cd-breadcrumb li,
.cd-multi-steps li {
  display: inline-block;
  float: left;
  margin: 0.5em 0;
}

.cd-breadcrumb li::after,
.cd-multi-steps li::after {
  /* this is the separator between items */
  display: inline-block;
  content: '\00bb';
  margin: 0 .6em;
  color: #959fa5;
}

.cd-breadcrumb li:last-of-type::after,
.cd-multi-steps li:last-of-type::after {
  /* hide separator after the last item */
  display: none;
}

.cd-breadcrumb li > *,
.cd-multi-steps li > * {
  /* single step */
  display: inline-block;
  color: #2c3f4c;
}

.cd-breadcrumb li.current > *,
.cd-multi-steps li.current > * {
  /* selected step */
  color: #000000;
  font-weight: 400;
}

.no-touch .cd-breadcrumb a:hover,
.no-touch .cd-multi-steps a:hover {
  /* steps already visited */
  color: #000000;
}

.cd-breadcrumb.custom-separator li::after,
.cd-multi-steps.custom-separator li::after {
  /* replace the default arrow separator with a custom icon */
  content: '';
  height: 16px;
  width: 16px;
  background: url(../img/cd-custom-separator.svg) no-repeat center center;
  vertical-align: middle;
}

.cd-breadcrumb.custom-icons li > *::before,
.cd-multi-steps.custom-icons li > *::before {
  /* add a custom icon before each item */
  content: '';
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: .4em;
  margin-top: -2px;
  background: url(../img/cd-custom-icons-01.svg) no-repeat 0 0;
  vertical-align: middle;
}

.cd-breadcrumb.custom-icons li:not(.current):nth-of-type(2) > *::before,
.cd-multi-steps.custom-icons li:not(.current):nth-of-type(2) > *::before {
  /* change custom icon using image sprites */
  background-position: -20px 0;
}

.cd-breadcrumb.custom-icons li:not(.current):nth-of-type(3) > *::before,
.cd-multi-steps.custom-icons li:not(.current):nth-of-type(3) > *::before {
  background-position: -40px 0;
}

.cd-breadcrumb.custom-icons li:not(.current):nth-of-type(4) > *::before,
.cd-multi-steps.custom-icons li:not(.current):nth-of-type(4) > *::before {
  background-position: -60px 0;
}

.cd-breadcrumb.custom-icons li.current:first-of-type > *::before,
.cd-multi-steps.custom-icons li.current:first-of-type > *::before {
  /* change custom icon for the current item */
  background-position: 0 -20px;
}

.cd-breadcrumb.custom-icons li.current:nth-of-type(2) > *::before,
.cd-multi-steps.custom-icons li.current:nth-of-type(2) > *::before {
  background-position: -20px -20px;
}

.cd-breadcrumb.custom-icons li.current:nth-of-type(3) > *::before,
.cd-multi-steps.custom-icons li.current:nth-of-type(3) > *::before {
  background-position: -40px -20px;
}

.cd-breadcrumb.custom-icons li.current:nth-of-type(4) > *::before,
.cd-multi-steps.custom-icons li.current:nth-of-type(4) > *::before {
  background-position: -60px -20px;
}

@media screen and (min-width: 41.875em) {
  .cd-breadcrumb, .cd-multi-steps {
    padding: 0 1.2em;
  }
  .cd-breadcrumb li, .cd-multi-steps li {
    margin: 1.2em 0;
  }
  .cd-breadcrumb li::after, .cd-multi-steps li::after {
    margin: 0 1em;
  }
}

/* --------------------------------
Triangle breadcrumb
-------------------------------- */
@media screen and (min-width: 41.875em) {
  .cd-breadcrumb.triangle {
    /* reset basic style */
    background-color: transparent;
    padding: 0;
  }
  .cd-breadcrumb.triangle li {
    position: relative;
    padding: 0;
    margin: 4px 4px 4px 0;
  }
  .cd-breadcrumb.triangle li:last-of-type {
    margin-right: 0;
  }
  .cd-breadcrumb.triangle li > * {
    position: relative;
    padding: 1em .8em 1em 2.5em;
    color: #2c3f4c;
    background-color: #edeff0;
    /* the border color is used to style its ::after pseudo-element */
    border-color: #edeff0;
  }
  .cd-breadcrumb.triangle li.current > * {
    /* selected step */
    color: #ffffff;
    background-color: #96c03d;
    border-color: #96c03d;
  }
  .cd-breadcrumb.triangle li:first-of-type > * {
    padding-left: 1.6em;
    border-radius: .25em 0 0 .25em;
  }
  .cd-breadcrumb.triangle li:last-of-type > * {
    padding-right: 1.6em;
    border-radius: 0 .25em .25em 0;
  }
  .no-touch .cd-breadcrumb.triangle a:hover {
    /* steps already visited */
    color: #ffffff;
    background-color: #2c3f4c;
    border-color: #2c3f4c;
  }
  .cd-breadcrumb.triangle li::after,
  .cd-breadcrumb.triangle li > *::after {
    /*
			li > *::after is the colored triangle after each item
			li::after is the white separator between two items
		*/
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    content: '';
    height: 0;
    width: 0;
    /* 48px is the height of the <a> element */
    border: 24px solid transparent;
    border-right-width: 0;
    border-left-width: 20px;
  }
  .cd-breadcrumb.triangle li::after {
    /* this is the white separator between two items */
    z-index: 1;
    transform: translateX(4px);
    border-left-color: #ffffff;
    /* reset style */
    margin: 0;
  }
  .cd-breadcrumb.triangle li > *::after {
    /* this is the colored triangle after each element */
    z-index: 2;
    border-left-color: inherit;
  }
  .cd-breadcrumb.triangle li:last-of-type::after,
  .cd-breadcrumb.triangle li:last-of-type > *::after {
    /* hide the triangle after the last step */
    display: none;
  }
  .cd-breadcrumb.triangle.custom-separator li::after {
    /* reset style */
    background-image: none;
  }
  .cd-breadcrumb.triangle.custom-icons li::after,
  .cd-breadcrumb.triangle.custom-icons li > *::after {
    /* 50px is the height of the <a> element */
    border-top-width: 25px;
    border-bottom-width: 25px;
  }
  @-moz-document url-prefix() {
    .cd-breadcrumb.triangle li::after,
    .cd-breadcrumb.triangle li > *::after {
      /* fix a bug on Firefix - tooth edge on css triangle */
      border-left-style: dashed;
    }
  }
}

/* --------------------------------
Custom icons hover effects - breadcrumb and multi-steps
-------------------------------- */
@media screen and (min-width: 41.875em) {
  .no-touch .cd-breadcrumb.triangle.custom-icons li:first-of-type a:hover::before,
  .cd-breadcrumb.triangle.custom-icons li.current:first-of-type em::before,
  .no-touch .cd-multi-steps.text-center.custom-icons li:first-of-type a:hover::before,
  .cd-multi-steps.text-center.custom-icons li.current:first-of-type em::before {
    /* change custom icon using image sprites - hover effect or current item */
    background-position: 0 -40px;
  }
  .no-touch .cd-breadcrumb.triangle.custom-icons li:nth-of-type(2) a:hover::before,
  .cd-breadcrumb.triangle.custom-icons li.current:nth-of-type(2) em::before,
  .no-touch .cd-multi-steps.text-center.custom-icons li:nth-of-type(2) a:hover::before,
  .cd-multi-steps.text-center.custom-icons li.current:nth-of-type(2) em::before {
    background-position: -20px -40px;
  }
  .no-touch .cd-breadcrumb.triangle.custom-icons li:nth-of-type(3) a:hover::before,
  .cd-breadcrumb.triangle.custom-icons li.current:nth-of-type(3) em::before,
  .no-touch .cd-multi-steps.text-center.custom-icons li:nth-of-type(3) a:hover::before,
  .cd-multi-steps.text-center.custom-icons li.current:nth-of-type(3) em::before {
    background-position: -40px -40px;
  }
  .no-touch .cd-breadcrumb.triangle.custom-icons li:nth-of-type(4) a:hover::before,
  .cd-breadcrumb.triangle.custom-icons li.current:nth-of-type(4) em::before,
  .no-touch .cd-multi-steps.text-center.custom-icons li:nth-of-type(4) a:hover::before,
  .cd-multi-steps.text-center.custom-icons li.current:nth-of-type(4) em::before {
    background-position: -60px -40px;
  }
}

/* --------------------------------
Multi steps indicator
-------------------------------- */
@media screen and (min-width: 41.875em) {
  .cd-multi-steps {
    /* reset style */
    background-color: transparent;
    padding: 0;
  }
  .cd-multi-steps li {
    position: relative;
    float: none;
    margin: 0.4em 40px 0.4em 0;
  }
  .cd-multi-steps li:last-of-type {
    margin-right: 0;
  }
  .cd-multi-steps li::after {
    /* this is the line connecting 2 adjacent items */
    position: absolute;
    content: '';
    height: 4px;
    background: #f5f4f4;
    /* reset style */
    margin: 0;
  }
  .cd-multi-steps li.visited::after {
    background-color: #96c03d;
  }
  .cd-multi-steps li > *,
  .cd-multi-steps li.current > * {
    position: relative;
    color: #2c3f4c;
  }
  .cd-multi-steps.custom-separator li::after {
    /* reset style */
    height: 4px;
    background: #1a1a1a;
  }
  .cd-multi-steps.text-center li::after {
    width: 100%;
    top: 50%;
    left: 100%;
    transform: translateY(-50%) translateX(-1px);
  }
  .cd-multi-steps.text-center li > * {
    z-index: 1;
    padding: .6em 1em;
    border-radius: .25em;
    background-color: #f5f4f4;
  }
  .no-touch .cd-multi-steps.text-center a:hover {
    background-color: #2c3f4c;
  }
  .cd-multi-steps.text-center li.current > *,
  .cd-multi-steps.text-center li.visited > * {
    color: #fefefe;
    background-color: #000000;
  }
  .cd-multi-steps.text-center.custom-icons li.visited a::before {
    /* change the custom icon for the visited item - check icon */
    background-position: 0 -60px;
  }
  .cd-multi-steps.text-top li,
  .cd-multi-steps.text-bottom li {
    width: 80px;
    text-align: center;
  }
  .cd-multi-steps.text-top li::after,
  .cd-multi-steps.text-bottom li::after {
    /* this is the line connecting 2 adjacent items */
    position: absolute;
    left: 50%;
    /* 40px is the <li> right margin value */
    width: calc(100% + 40px);
  }
  .cd-multi-steps.text-top li > *::before,
  .cd-multi-steps.text-bottom li > *::before {
    /* this is the spot indicator */
    content: '';
    position: absolute;
    z-index: 1;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #edeff0;
  }
  .cd-multi-steps.text-top li.visited > *::before,
  .cd-multi-steps.text-top li.current > *::before,
  .cd-multi-steps.text-bottom li.visited > *::before,
  .cd-multi-steps.text-bottom li.current > *::before {
    background-color: #96c03d;
  }
  .no-touch .cd-multi-steps.text-top a:hover,
  .no-touch .cd-multi-steps.text-bottom a:hover {
    color: #96c03d;
  }
  .no-touch .cd-multi-steps.text-top a:hover::before,
  .no-touch .cd-multi-steps.text-bottom a:hover::before {
    box-shadow: 0 0 0 3px rgba(150, 192, 61, 0.3);
  }
  .cd-multi-steps.text-top li::after {
    /* this is the line connecting 2 adjacent items */
    bottom: 4px;
  }
  .cd-multi-steps.text-top li > * {
    padding-bottom: 20px;
  }
  .cd-multi-steps.text-top li > *::before {
    /* this is the spot indicator */
    bottom: 0;
  }
  .cd-multi-steps.text-bottom li::after {
    /* this is the line connecting 2 adjacent items */
    top: 3px;
  }
  .cd-multi-steps.text-bottom li > * {
    padding-top: 20px;
  }
  .cd-multi-steps.text-bottom li > *::before {
    /* this is the spot indicator */
    top: 0;
  }
}

/* --------------------------------
Add a counter to the multi-steps indicator
-------------------------------- */
.cd-multi-steps.count li {
  counter-increment: steps;
}

.cd-multi-steps.count li > *::before {
  content: counter(steps) " - ";
}

@media screen and (min-width: 41.875em) {
  .cd-multi-steps.text-top.count li > *::before,
  .cd-multi-steps.text-bottom.count li > *::before {
    /* this is the spot indicator */
    content: counter(steps);
    height: 26px;
    width: 26px;
    line-height: 26px;
    color: #ffffff;
  }
  .cd-multi-steps.text-top.count li:not(.current) em::before,
  .cd-multi-steps.text-bottom.count li:not(.current) em::before {
    /* steps not visited yet - counter color */
    color: #2c3f4c;
  }
  .cd-multi-steps.text-top.count li::after {
    bottom: 11px;
  }
  .cd-multi-steps.text-top.count li > * {
    padding-bottom: 34px;
  }
  .cd-multi-steps.text-bottom.count li::after {
    top: 11px;
  }
  .cd-multi-steps.text-bottom.count li > * {
    padding-top: 34px;
  }
}

/* ===================================================================================================================
   Buttons
   ================================================================================================================ */
.button,
.submit,
[class*="mod_iso"] .review a.edit,
.page_sidebar-subnavigation a,
.page_sidebar-subnavigation span,
.mod_iso_orderhistory .document_number a {
  margin: 1rem 0;
  padding: 10px 10px 8px 10px;
  font-weight: 400;
  color: #000000;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid #000000;
}

.page_sidebar-subnavigation span {
  display: block;
}

.mod_iso_productfilter a.clear_filters {
  display: block;
  margin: 0 0 1rem 0;
  padding-top: 12px;
  padding-bottom: 11px;
}

.mod_iso_orderhistory .document_number a {
  padding: 4px 2px 2px 2px;
}

.button:focus,
.button:hover,
.submit:focus,
.submit:hover,
[class*="mod_iso"] .review a.edit:focus,
[class*="mod_iso"] .review a.edit:hover,
.page_sidebar-subnavigation a:focus,
.page_sidebar-subnavigation a:hover,
.page_sidebar-subnavigation span,
.mod_iso_orderhistory .document_number a:focus,
.mod_iso_orderhistory .document_number a:hover {
  color: #fefefe;
  background-color: #1a1a1a;
}

.mod_registration .submit {
  margin-top: 1.5625rem;
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.button_hamburger span {
  width: 25px;
  height: 2px;
  background-color: #232323;
  display: block;
  margin: 3px auto;
  transition: all 0.3s ease-in-out;
}

.button_hamburger:focus,
.button_hamburger:hover {
  cursor: pointer;
}

/* ===================================================================================================================
   User
   ================================================================================================================ */
.mod_personalData {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #000000;
}

.mod_registration .fields,
.mod_personalData .fields {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  max-width: none;
  margin-left: -10px;
  margin-right: -10px;
}

@media print, screen and (min-width: 40em) {
  .mod_registration .fields,
  .mod_personalData .fields {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.mod_registration .widget,
.mod_personalData .widget {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 50%;
}

@media print, screen and (min-width: 40em) {
  .mod_registration .widget,
  .mod_personalData .widget {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.mod_registration .widget:nth-child(1),
.mod_personalData .widget:nth-child(1),
.mod_registration .widget:nth-child(10),
.mod_personalData .widget:nth-child(10) {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .mod_registration .widget:nth-child(1),
  .mod_personalData .widget:nth-child(1),
  .mod_registration .widget:nth-child(10),
  .mod_personalData .widget:nth-child(10) {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.mod_login .fields,
.mod_lostPassword .fields {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  max-width: none;
  margin-left: -10px;
  margin-right: -10px;
}

@media print, screen and (min-width: 40em) {
  .mod_login .fields,
  .mod_lostPassword .fields {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.mod_login .widget,
.mod_lostPassword .widget {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .mod_login .widget,
  .mod_lostPassword .widget {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 30em) {
  .mod_login .widget,
  .mod_lostPassword .widget {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 50%;
  }
}

@media print, screen and (min-width: 30em) and (min-width: 40em) {
  .mod_login .widget,
  .mod_lostPassword .widget {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

/* ===================================================================================================================
   Accordion
   ================================================================================================================ */
.ce_accordion .toggler {
  position: relative;
  outline: 0;
  cursor: pointer;
  padding: 0.9375rem 0;
  text-transform: uppercase;
  font-size: 0.9375rem;
  font-weight: 400;
  letter-spacing: 0.1em;
}

.ce_accordion .toggler:before {
  content: "";
  margin-right: 10px;
  margin-right: 5px;
  font-size: 16px;
}

.ce_accordion .toggler:after {
  content: "";
  margin-left: 10px;
  position: absolute;
  top: calc(50% - 7px);
  right: 0;
  font-size: 14px;
}

.ce_accordion .toggler.active:after {
  content: "";
  margin-left: 10px;
  font-size: 16px;
}

.ce_accordion .ui-accordion-content-active {
  padding-bottom: 0.875rem;
}

/* ===================================================================================================================
   Modal (Reveal)
   ================================================================================================================ */
.link_modal-open {
  padding-bottom: 1px;
  border-bottom: 1px solid #000000;
}

.reveal .button_wrapper {
  text-align: center;
}

/* ===================================================================================================================
   RSCE 1-001 - CE Item - Intro Text (Template: rsce_1_001_intro_text)
   ================================================================================================================ */
/* ===================================================================================================================
   RSCE 2-001 - CE Item - Media Teaser (Template: rsce_2_001_media_teaser)
   ================================================================================================================ */
.ce_item-media-teaser {
  position: relative;
  height: 100vh;
  overflow: hidden;
  border: 1.25rem solid #fefefe;
  animation-name: fade-0-to-100;
  animation-duration: 5s;
}

@media print, screen and (min-width: 40em) {
  .ce_item-media-teaser {
    border: 1.875rem solid #fefefe;
  }
}

@keyframes fade-0-to-100 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ce_item-media-teaser__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: 50% 50% no-repeat;
  background-size: cover;
}

.ce_item-media-teaser__background video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
}

.ce_item-media-teaser__content-wrapper-outside {
  position: relative;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}

.ce_item-media-teaser__content-wrapper-inside {
  padding-left: 20px;
  padding-right: 20px;
  animation-name: fade-0-to-100;
  animation-duration: 10s;
}

@keyframes fade-0-to-100 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media print, screen and (min-width: 40em) {
  .ce_item-media-teaser__content-wrapper-inside {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.ce_item-media-teaser__link a {
  text-align: center;
  color: #fefefe;
  padding-top: 3rem;
  display: block;
}

@media print, screen and (min-width: 40em) {
  .ce_item-media-teaser__link a {
    position: relative;
  }
}

.ce_item-media-teaser__link a:before {
  content: "";
  color: #fefefe;
  position: relative;
  font-size: 2.25rem;
}

/* ===================================================================================================================
   RSCE 1-010 - CE Item - Teaser (Template: rsce_1_001_teaser)
   ================================================================================================================ */
.ce_item-teaser .columns > div, .ce_item-teaser .columns > a {
  height: 100%;
  background-color: #e4e4e4;
  padding: 1.5rem;
}

.ce_item-teaser .columns > a {
  display: block;
  width: 100%;
}

.ce_item-teaser .bg-image {
  background-repeat: no-repeat;
  background-size: cover;
}

/* ===================================================================================================================
   RSCE 2-001 - CE Item - Orbit Slider (Template: rsce-2-010-orbit-slider)
   ================================================================================================================ */
.orbit {
  position: relative;
}

.orbit-caption {
  display: none;
}

.ce_orbit_slider.slider-background li {
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.ce_orbit_slider.slider-background ul {
  height: 100vh !important;
}

.ce_orbit_slider .main-caption {
  position: relative;
  color: #fefefe;
  bottom: 0;
  right: 0;
  background-color: black;
  padding: 1rem 1.5rem 0.5rem 1.5rem;
  width: 100%;
}

@media print, screen and (min-width: 40em) {
  .ce_orbit_slider .main-caption {
    position: absolute;
    bottom: 15%;
    width: auto;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.ce_orbit_slider .main-caption h3 {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 400;
}

@media print, screen and (min-width: 40em) {
  .ce_orbit_slider .main-caption h3 {
    font-size: 1.125rem;
  }
}

/* ====================================================================================================================
   Development
   ================================================================================================================= */
.remove-margin {
  margin-left: -10px;
  margin-right: -10px;
}

@media print, screen and (min-width: 40em) {
  .remove-margin {
    margin-left: -15px;
    margin-right: -15px;
  }
}
